import React, { useState, useEffect,ChangeEvent } from 'react'
import { Button } from "../../Components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../Components/ui/select';
import { Input } from '../../Components/ui/input';
import { Label } from "../../Components/ui/label";
import { Textarea } from '../../Components/ui/textarea';
import { FaTrash, FaTimes, FaFilePdf, FaFileWord } from "react-icons/fa";
import { FaPlus, FaCaretDown } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import {Container, Box, Typography, TextField, IconButton } from "@mui/material";
import axios from "axios";
//import { ToastContainer, toast } from "react-toastify";
import { toast, useToast } from "../../Components/ui/use-toast";
import { Toaster } from "../../Components/ui/toaster";
import { Value } from '@radix-ui/react-select';
import { useNavigate,useParams,useLocation } from 'react-router-dom';
import config from  '../../config.json';
import { ChevronDownIcon, PlusIcon } from '@radix-ui/react-icons';
import { useDispatch } from "react-redux";
import { setCreateBreadCrumb } from "../../State/slices/AdvertiserAccountSlice";
import { title } from 'process';
import { Description } from '@radix-ui/react-toast';
import { CheckIcon } from "@radix-ui/react-icons";
import { Card } from "../../Components/ui/card";
import { RootState } from '../../State/store';
import { useSelector } from "react-redux";
interface BoxItem {
  action: string;
}



// Define the Country type
interface Language {
  language_id: number;
  language_name: string;
}

interface TemplatePlatform {
  platform_id: number;
  platform_name: string;
}

interface GetButtonTypeList {
  button_id: number;
  button_type: string;
}

interface Template {
  template_id: number;
  template_name: string;
}

interface Content {
  content_id: number;
  content_name: string;
}
interface RowData {
  buttonType: string;
  buttonText: string;
  buttonTypeDropdown: string;
  websiteUrl: string;
}


// interface GetButtonType {
//   button_id: number;
//   button_type: string;
// }

interface Channel {
  channel_id: number;
  channel_name: string;
}

const textAreaIcon=()=>{
    return(
      <div className="relative w-8 h-8 flex justify-center items-center" >
      <div
        className="absolute rounded w-[3px] h-8 transform rotate-45" style={{ borderLeft: '3px solid #64748B' }}
      />
      <div
        className="absolute w-[3px] h-4 rounded transform rotate-45 translate-x-2 translate-y-2" style={{ borderLeft: '3px solid #64748B' }}
      />
    </div>
    )
  }

const CreateTemplate: React.FC = () => {

const handleDropdownToggle = () => {
  setShowDropdown(!showDropdown);
};

const handleActionSelect = (action: string) => {
  setSelectedAction(action);
  setShowDropdown(false);
};
  const navigate = useNavigate();
  const location = useLocation();
  const templateId = location.state?.templateId || "";

  const [platform , setPlatform] = useState('');
  const [templatePlatform, setTemplatePlatform] = useState<TemplatePlatform[]>([]); 
  const [languageList, setLanguageList] = useState<Language[]>([]); 
  const [language,setLanguage] = useState('3');
  const [Content,setContent] = useState('');
  const [ContentList,setContentList] = useState<Content[]>([]);
  const [buttonType, setButtonType] = useState('');
  const [buttonList, setButtonList] = useState<GetButtonTypeList[]>([]);
  const [templateList, setTemplateList] = useState<Template[]>([]);
  const [templateName, setTemplateName] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [textInput, setTextInput] = useState<string>('');
  const [headerType, setHeaderType] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [bodyText, setBodyText] = useState<string>("");
  const [FooterText, setFooterText] = useState<string>("");
  const [buttonT , setButtonT] = useState<number>(0);
  const [templatePreview, setTemplatePreview] = useState<string>("");
  const [buttonData, setButtonData] = useState<{ buttonText: string; websiteUrl: string }>({
    buttonText: "",
    websiteUrl: "",
  });
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [buttonTypeDropdown, setButtonTypeDropdown] = useState<string>("static");
  const [buttonText, setButtonText] = useState<string>("");
  const [websiteUrl, setWebsiteUrl] = useState<string>("");
  const [boxes, setBoxes] = useState<BoxItem[]>([]);
  const [channelList, setChannelList] = useState<Channel[]>([]); // State for the channel list
  const [channel, setChannel] = useState('');
  const [templateType , setTemplateType] = useState('');

  const [updateplatform , setUpdatePlatform] = useState('');
  const [updateLanguage,setUpdateLanguage] = useState('');
  const [updateButtonType,setUpdateButtonType] = useState('');
  const [updateContentList,setUpdatedContentList] = useState('');
  const [updateTemplateList,setUpdatedTemplateList] = useState('');
  const [updateChannel,setUpdateChannel]=useState('');
  const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState('');

  const [PlatformNameError, setPlatformNameError] = useState<string | null>(null);
  const [templateError, setTemplateError] = useState<string | null>(null);
  const [LanguageError, setLanguageError] = useState<string | null>(null);
  const [channelError, setChannelError] = useState<string | null>(null);
  const [BodyError, setBodyError] = useState<string | null>(null);
  const dispatch = useDispatch();
    const toast = useToast();

  const [rows, setRows] = useState<RowData[]>([]);
  const [warning, setWarning] = useState("");
  const [selectedButtonIds, setSelectedButtonIds] = useState<number[]>([]);
  const buttonTypeArray = selectedButtonIds; // Selected button IDs
  const buttonTextArray = rows.map((row) => row.buttonText); 
  const currentDate = new Date().toISOString(); 
  const workspaceId = useSelector((state:RootState)=>state.authentication.workspace_id);

    const handleRowInputChange = (index: number, field: keyof RowData, value: string) => {
        const updatedRows = [...rows];
        console.log("Button Value:" + value);

        updatedRows[index] = { ...updatedRows[index], [field]: value }; 

        if (field === "buttonType") {
            const selectedButton = buttonList.find((button) => button.button_type === value);
            if (selectedButton) {
                updatedRows[index].buttonType = selectedButton.button_type;

                // Update the selectedButtonIds array
                const updatedButtonIds = [...selectedButtonIds];
                updatedButtonIds[index] = selectedButton.button_id; // Store the button_id for the row
                setSelectedButtonIds(updatedButtonIds);
            } else {
                updatedRows[index].buttonType = "No buttons are selected";

                // Reset the button_id for this row if no valid selection
                const updatedButtonIds = [...selectedButtonIds];
                updatedButtonIds[index] = 0; // Or any default value
                setSelectedButtonIds(updatedButtonIds);
            }
        }

          // Handle buttonTypeDropdown logic to update URLType
          if (field === "buttonTypeDropdown") {
            updatedRows[index].buttonTypeDropdown = value; 
            console.log(`Dropdown value for row ${index}:`, value);
             // Set the URLType property for the row
          }


        setRows(updatedRows);
    };

    const handleAddRow = () => {
        if (rows.length >= 3) {
            setWarning("You can only add up to 3 buttons.");
        } else {
            setRows([
                ...rows,
                {
                    buttonType: "",
                    buttonText: "",
                    buttonTypeDropdown: "static",
                    websiteUrl: "",
                },
            ]);

            // Add a default value (e.g., 0) to the selectedButtonIds array
            setSelectedButtonIds([...selectedButtonIds, 0]);

            setWarning("");
        }
    };


  useEffect(() => {
    const fetchConfig = async () => {
        try {
            const response = await fetch('/config.json');
            const config = await response.json();
            setApiUrlAdvAcc(config.ApiUrlAdvAcc);  
        } catch (error) {
            console.error('Error loading config:', error);
        }
    };

    fetchConfig();
}, []);
  
  const getLanguageList = async () => {
    setLoading(true);
 

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetLanguageList`);
   
      // Assuming the response data contains a 'CountryList' field as discussed earlier
      if (response.data && response.data.languageList) {
        setLanguageList(response.data.languageList);
        console.log("Language List : " , response.data.languageList)
      } else {
        console.log("No Language list available in response.");
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching Language list:", error);
    } finally {
      setLoading(false);
    }
  };


  const getTemplatePlatform = async () => {
    setLoading(true);
 

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetTemplatePlatform`);
      if (response.data && response.data.templatePlatform) {
        setTemplatePlatform(response.data.templatePlatform);
        console.log("Template Platform : " , response.data.templatePlatform)
      } else {
        console.log("No Template Platdform available in response.");
      }
    } catch (error) {
        console.error("Error fetching Template Platform:", error);
    } finally {
      setLoading(false);
    }
  };


  const getbuttonType = async () => {
    setLoading(true);
 

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetButtonType`);
      if (response.data && response.data.buttonType) {
        setButtonList(response.data.buttonType);
        console.log("ButtonType List : " , response.data.buttonType)
      } else {
        console.log("No Button Type available in response.");
      }
    } catch (error) {
      console.error("Error fetching Button Type:", error);
    } finally {
      setLoading(false);
    }
  };


 const getContentType = async () => {
    setLoading(true);
 

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetContentList`);
   
      if (response.data && response.data.contentList) {
        setContentList(response.data.contentList);
        console.log("ContentType : " , response.data.contentList)
      } else {
        console.log("No Content Type available in response.");
      }
    } catch (error) {

      console.error("Error fetching Content Type:", error);
    } finally {
      setLoading(false);
    }
  };


  const getTemplateList = async () => {
    setLoading(true);
 
    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetTemplateList`);
      
      if (response.data && response.data.templateList) {
        setTemplateList(response.data.templateList);
        console.log("Template List : " , response.data.templateList)
      } else {
        console.log("No template list available in response.");
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching template list:", error);
    } finally {
      setLoading(false);
    }
  };

  const getChannelList = async () => {
    setLoading(true);
 

    try {
    const response = await axios.get(`${apiUrlAdvAcc}/GetChannelList`);
     
     
      if (response.data && response.data.channelList) {
        setChannelList(response.data.channelList);
        console.log("Channel List : " , response.data.channelList)
      } else {
        console.log("No channel list available in response.");
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching country list:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadTemplateList = async (id: any) => {
    setLoading(true);
    console.log("templateId API::" , id)
  
    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetTemplateDetailsById?TemplateId=`+id);
      console.log ("response.data.templateDetails:"+response.data.templateDetails)
      if (response.data && response.data.templateDetails) {
        const templateDetailslocal = response.data.templateDetails[0];
       
        console.log("Template List By Id:", templateDetailslocal);
        console.log("Ttemplate_type:", templateDetailslocal.template_type);
  
        setUpdatePlatform(templateDetailslocal.platform_name);
        setTemplateName(templateDetailslocal.template_name); // Set other fields as needed
        setUpdateChannel(templateDetailslocal.template_type); 
        setUpdateLanguage(templateDetailslocal.language_name);
        setUpdatedContentList(templateDetailslocal.template_header);
        setBodyText(templateDetailslocal.template_body);
        setFooterText(templateDetailslocal.template_footer);
        setUpdateButtonType(templateDetailslocal.button_type);
        setButtonText(templateDetailslocal.button_text);
        setHeaderType(templateDetailslocal.template_header);
        setTemplatePreview(templateDetailslocal.template_body);

        handleOptionChange(templateDetailslocal.template_header);

      } else {
        console.log("No Templates details available in response.");
      }
    } catch (error) {
      console.error("Error fetching template details:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
        try {
            if (apiUrlAdvAcc) {
                await getTemplatePlatform(); // Ensure the channel list is loaded first
                if (templateId) {
                    await loadTemplateList(templateId); // Load templates based on templateId
                }
                await Promise.all([
                    getLanguageList(),
                    getbuttonType(),
                    getContentType(), 
                    getChannelList(),
                ]);
                // updateTemplatePreview(); // Call to update the preview after loading data
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, [apiUrlAdvAcc, templateId]); // Depend on apiUrlAdvAcc and templateId



const validatePlatformName = () => {
  if (!platform && !updateplatform) {
    setPlatformNameError("Platform Name is required");
    return false;
  }setPlatformNameError(null);
  return true;
};

const validateTemplateName = () => {
  if (!templateName.trim()) {
    setTemplateError("Template Name is Required");
    return false;
  }
  setTemplateError(null);
  return true;
};

const validateLanguage = () => {
  if (!language && !updateLanguage) {
    setLanguageError("Please select a Language");
    return false;
  }setLanguageError(null);
  return true;
};



const validateChannel = () => {
  if (!channel && !updateChannel) {
    setChannelError("Please select a channel");
    return false;
  }
  setChannelError(null);
  return true;
};

const validateBody = () => {
  if (!bodyText.trim()) {
    setBodyError("");
    return false;
  }
  setBodyError(null);
  return true;
};

 
  const handleEdit = async () => {

    console.log("buttonType:" , buttonType);

    const selectedplatform = templatePlatform.find(findPlatform => findPlatform.platform_name === updateplatform);
    const updatePlatformId = selectedplatform ? selectedplatform.platform_id : null;

    const selectedLanguage = languageList.find(findLanguage => findLanguage.language_name === updateLanguage);
    const updateLanguageId = selectedLanguage ? selectedLanguage.language_id : null;

    const selectedButtonType = buttonList.find(findButtonType => findButtonType.button_id === parseInt(updateButtonType));
    // const updateButtonId = selectedButtonType ? selectedButtonType.button_id : null;
    const updateButtonId = selectedButtonType ? String(selectedButtonType.button_id) : null;

    const selectedContentType = ContentList.find(findContentType => findContentType.content_name  === updateContentList);
    const updateContentId = selectedContentType ? selectedContentType.content_id : null;
   
    const selectedTemplateType = templateList.find(findTemplateType => findTemplateType.template_name  === updateTemplateList);
    const updateTemplateId = selectedTemplateType ? selectedTemplateType.template_id : null;

    const selectedChannel = channelList.find(findchannel => findchannel.channel_name=== updateChannel);
    const updateChannelId = selectedChannel ? selectedChannel.channel_id : null;


    console.log("platform:",platform,"updatePlatformId:", updatePlatformId)
    console.log("language:" , language , "updateLanguageId::" , updateLanguageId)
    console.log("buttonType:" , buttonType , "updateButtonId::" , updateButtonId )
    console.log("TemplatType:" , templateType , "updateTemplateId::" , updateTemplateId )


if (
  !validatePlatformName() ||
  !validateTemplateName() ||
  !validateLanguage() ||
  !validateChannel() ||
  !validateBody()
) {
  // If validation fails, do not submit
  return;
}

    try{
      console.log("BeforebuttonType::"+ buttonType);
      setButtonType(JSON.stringify(buttonTypeArray));
      setButtonText(JSON.stringify(buttonTextArray));
      console.log("AfterbuttonType::"+ buttonType); 
      const data={
       
        "TemplateId":templateId,
        "PlatformName": platform==""?updatePlatformId:platform,
        "TemplateType": templateType==""?updateChannelId:templateType,
        "TemplateName": templateName,
        "TemplateLanguage": language==""?updateLanguageId:language,
        "TemplateHeader": headerType,
        "TemplateBody": bodyText,
        "TemplateFooter": FooterText,
        "Components": "String",
        "ButtonType":  buttonType==""?updateButtonId:buttonType,
        "ButtonText": buttonText,
        "UpdateBy":"1",
        "UpdatedDate":currentDate,
        "Status":"Pending",
        "URLType":buttonTypeDropdown,
        "WebsiteURL":""
      }
  
      console.log("loadingdata:" ,data);
      console.log(data);
      
      
      const response = await axios.put(`${apiUrlAdvAcc}/UpdateTemplate?TemplateId=${templateId}`, data);
      
      if (response.data.status === 'Success') {
        const Close=()=>{
          navigate('/navbar/TemplateList');
        }
        Close();
        toast.toast({
          title:"Success",
          description:"The Template details were updated successfully"
        })
        
      } else {
          console.error('Upload failed:', response.data.Status_Description);
          toast.toast({
            title:"Error",
            description:"An error occurred while updating the Template details"
          })
         
          setTimeout(() =>{/* wait for 1 second */},1000)
      }
    } catch (e) {
      console.error("Error in submitting form", e);
    }
  }


  const handleBodyTextChange = (text: string) => {
    setBodyText(text);
  
    // Extract placeholders (e.g., {{1}}, {{2}}) from the text
    const placeholderRegex = /{{(\d+)}}/g;
    const matches = Array.from(text.matchAll(placeholderRegex));
    const detectedIndexes = matches.map((match) => parseInt(match[1], 10));
  
    // Update `boxes` to match the detected placeholders
    const newBoxes = [...boxes];
    detectedIndexes.forEach((index) => {
      if (!newBoxes[index - 1]) {
        newBoxes[index - 1] = { action: "" }; // Add missing box
      }
    });
    // Remove any extra boxes that are not in the body text
  const filteredBoxes = newBoxes.slice(0, Math.max(...detectedIndexes, 0));
  setBoxes(filteredBoxes);

  updateTemplatePreview(); // Update preview dynamically
};


const addVariable = () => {
    const newPlaceholder = `{{${boxes.length + 1}}}`;
    setBodyText(bodyText + ` ${newPlaceholder}`);
    setBoxes([...boxes, { action: "" }]);
  };

const updateButtonAction = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newBoxes = boxes.slice();
    newBoxes[index].action = event.target.value;
    setBoxes(newBoxes);
    updateTemplatePreview();
  };

  const onTextboxDelete = (index: number) => {
  
      const newBoxes = boxes.filter((_, i) => i !== index);
      let updatedBodyText = bodyText;
      const placeholderToRemove = `{{${index + 1}}}`;
      updatedBodyText = updatedBodyText.replace(placeholderToRemove, "").trim();

      for (let i = index + 1; i <= boxes.length; i++) {
        const oldPlaceholder = `{{${i + 1}}}`;
        const newPlaceholder = `{{${i}}}`;
        updatedBodyText = updatedBodyText.replace(new RegExp(`\\{\\{${i + 1}\\}\\}`, "g"), newPlaceholder);
      }

      setBoxes(newBoxes);
      setBodyText(updatedBodyText);
      updateTemplatePreview();
      };
  
  useEffect(() => {
    updateTemplatePreview();
}, [bodyText, boxes, FooterText, file, headerType, buttonData, buttonText, websiteUrl]);


  const updateTemplatePreview = () => {
    let updatedText = bodyText;

    const placeholderRegex = /{{(\d+)}}/g;
    let match;

    while ((match = placeholderRegex.exec(bodyText)) !== null) {
      const index = parseInt(match[1], 10) - 1;

      if (boxes[index] && boxes[index].action) {
        updatedText = updatedText.replace(match[0], boxes[index].action);
      } else {
        updatedText = updatedText.replace(match[0], "");
      }
    }

    // updatedText += `\n\n${FooterText}`;
    setTemplatePreview(updatedText);
  };

  const handleOptionChange = (value: string) => {
    // debugger
    setSelectedOption(value);
    setSelectedFile(null);  
    setTextInput('');      
    setHeaderType(value);
    // debugger
  };


  const handleOptionChange1 = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHeaderType(e.target.value);
  };


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (isValidFile(file)) {
        setSelectedFile(file);
      } else {
        alert('Invalid file type selected.');
      }
    }
  };

  const isValidFile = (file: File) => {
    const fileType = file.type;
    if (selectedOption === 'image') {
      return fileType === 'image/jpeg' || fileType === 'image/png';
    }
    if (selectedOption === 'video') {
      return fileType.includes('video/');
    }
    if (selectedOption === 'document') {
      return (
        fileType === 'application/pdf' ||
        fileType === 'application/msword' ||
        fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        fileType === 'application/vnd.ms-excel' ||
        fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    }
    return false;
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextInput(e.target.value);
  };


  const handleFooterChange = (value: string) => {
    setFooterText(value); // Directly set the string value
  };

  const isFormValid = () => {
    return (
      templatePlatform &&
      templateList&&
      languageList&&
      headerType &&
      bodyText &&
      FooterText &&
      buttonType &&
      buttonText

    );
  };

 
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(e.target.value);
  };

  const resetForm = () => {
    setPlatform("");
    setTemplateName("");
    setTemplateType("");
    setLanguage("");
    setHeaderType("");
    setBodyText("");
    setFooterText("");
    setButtonType("");
    setButtonText("");

};

  const handleSubmit = async () => {
    if (
      !validatePlatformName() &&
      !validateTemplateName() &&
      !validateLanguage() &&
      !validateChannel() &&
      !validateBody()
    ) {
      // If validation fails, do not submit
      return;
    }
      const data={  
        "PlatformName": channel,
        "TemplateName": templateName,
        "TemplateLanguage": language,
        "TemplateHeader": headerType,
        "TemplateBody": bodyText,
        "TemplateFooter": FooterText,
        "Components": "String",
        "ButtonType": JSON.stringify(buttonTypeArray), 
        "ButtonText": JSON.stringify(buttonTextArray),
        "CreatedBy": 1,
        "CreatedDate": currentDate,
        "UpdateBy":1,
        "UpdatedDate":currentDate,
        "Status":"Live",
        "URLType":"",
        "WebsiteURL":"",
        "workspace_id":workspaceId
      }
      // debugger
      console.log(data);
      // debugger
      try{
      const response = await axios.post(`${apiUrlAdvAcc}/CreateTemplate`, data);
      console.log("response:" , response)
      if (response.data.status === 'Success') {
        resetForm();
        const Close=()=>{
          dispatch(setCreateBreadCrumb(false));
          navigate('/navbar/TemplateList')
        }
        Close();
        toast.toast({
          title:"Success",
          description:"The template details saved successfully"
        })
     
        setTimeout(() =>{

        },1000)       
      } else {
          console.error('Upload failed:', response.data.Status_Description);
          toast.toast({
            title:"Error",
            description:"An error occurred while saving the Template details"
          })
        
          setTimeout(() =>{/* wait for 1 second */},1000)
      }
    }
    catch(e){
      console.log('datas:', data);
      console.log("Error in submitting form");
    }
  }

    const renderPreview = () => {
        // Check if any content is available for the body or footer
        const isBodyEmpty = !bodyText?.trim();
        const isFooterEmpty = !FooterText?.trim();
        const isbuttonTextEmpty = !buttonText?.trim();
        const noContentSelected = !selectedOption && !selectedFile && isBodyEmpty && isFooterEmpty && rows.length === 0;

        return (
            <div className="flex flex-col justify-between w-full h-full">
                {noContentSelected ? (
                    <div className='flex w-full justify-center'>
                        <div className='flex-col justify-center text-left mt-[250px] items-center'>
                            <div>
                                {textAreaIcon()}
                            </div>
                            <div className='mt-[30px]'><p className="text-xl font-semibold">Mobile screen</p></div>
                            <div className='w-[140px] mt-[10px]' style={{ fontWeight: 500, fontSize: '14px' }}><p className="">Preview varies based on platform selection</p></div>
                        </div>
                    </div>

                ) : (
                    <>
                        {/* Image, Video, or Document Preview */}
                        <div className="flex justify-center">
                            {selectedOption === 'image' && selectedFile && (
                                <img
                                    src={URL.createObjectURL(selectedFile!)}
                                    alt="Preview"
                                    className="w-[320px] h-[200px] object-cover mx-auto"
                                />
                            )}
                            {selectedOption === 'video' && selectedFile && (
                                <video controls className="w-[320px] h-[200px] object-cover mx-auto">
                                    <source src={URL.createObjectURL(selectedFile)} />
                                </video>
                            )}
                            {selectedOption === 'document' && selectedFile && (
                                <p className=" w-[320px] h-[200px] object-cover mx-auto text-sm">Uploaded document: {selectedFile.name}</p>
                            )}
                        </div>

                        {/* Body Text */}
                        <div className=" flex-1 bg-white text-black overflow-visible w-full text-left">
                            {selectedOption === 'text' && textInput && (  // New text preview section
                                <p className="w-full text-base font-sans p-2 font-bold">{textInput}</p>
                            )}
                            {!isBodyEmpty ? (
                                <p className="text-base font-sans p-2">{templatePreview}</p>
                            ) : (
                                <p className="text-base font-medium"></p>
                            )}
                        </div>

                        {/* Footer Text */}
                        <div className="border-t mt-2 pt-2 text-center">
                            {!isFooterEmpty ? (
                                <p className="font-semibold text-black" style={{ fontSize: "14px" }}>{FooterText}</p>
                            ) : (
                                <p className="text-sm text-gray-500"></p>
                            )}
                        </div>

                        {/* WebURL */}
                        {/* <div className="border-t mt-2 pt-2 text-center">
            {!isbuttonTextEmpty ? (
              <p className="font-serif text-blue-400"><a href={websiteUrl} target="_blank" rel="noopener noreferrer">
              {buttonText}
            </a></p>
            ) : (
              <p className="text-sm text-gray-500"> </p>
            )}
          </div> */}

                        {/* Buttons Preview */}

                        <div className="border-t mt-2 pt-2 text-center">
                            {rows.map((row, index) => (
                                <p key={index} className="font-serif text-blue-400" style={{ fontSize: "14px" }}>
                                    <a
                                        href={row.websiteUrl}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {row.buttonText}
                                        <div className="border-t mt-2"></div>
                                    </a>
                                </p>
                            ))}
                        </div>
                        {/* <div className="border-t mt-2"></div> */}
                    </>
                )}
            </div>
        );
    };


      return (
         <div className="overflow-y-auto">

           <Toaster />
           <div className="fixed flex justify-end space-x-3 ml-[calc(70%-135px)] top-[-8px] z-20">
      <Button
        variant={'outline'}
        className="w-[80px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
        onClick={() => {
          dispatch(setCreateBreadCrumb(false));
          navigate('/navbar/templatelist')}}
      >
        Discard
      </Button>
      <Button className="w-fit" onClick={() => {
          if (templateId) {
              handleEdit(); 
          } else {
              handleSubmit(); 
          }
          console.log("Clicked"); 
      }}>
          Save and exit
      </Button>

    </div>
     <div className='p-3 '>
        <div className=" flex flex-col md:flex-row gap-6 mb-[100px]">
          <div className="space-y-6 w-full md:w-3/5 ">
            <div className="border p-4 rounded-lg">
              <h2 className="text-left mb-2" style={{ fontWeight: 600 , fontSize: '16px'}}>Platform</h2>
              <Select
                  value={channel} 
                  onValueChange={(value) => {
                    console.log("Selected Channel ID:", value);
                    setChannel(value);
                  }}
                >
                  <SelectTrigger className="text-gray-500"> {/* Apply gray text color to the trigger */}
                    <SelectValue
                      className="text-gray-500" 
                      placeholder={templateId ? updateChannel : "Select your Template channel"} 
                    />
                  </SelectTrigger>
                  <SelectContent>
                      {channelList
                        .filter(
                          (channel) =>
                            channel.channel_name.toLowerCase() === "whatsapp"
                        ) // Filter WhatsApp channel
                        .map((channel) => (
                          <SelectItem
                            className="text-gray-500"
                            key={channel.channel_id}
                            value={channel.channel_id as any}
                          >
                            {channel.channel_name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
               {PlatformNameError && (
                    <p className="text-red-500 text-xs text-left mt-2 ml-2">{PlatformNameError}</p>
                  )}
                    </div>
                  <div className="border p-4 rounded-lg text-left">
                    <h2 className="text-lg text-left mb-2" style={{ fontWeight: 600 , fontSize: '16px'}} >Template name and language</h2>                   
                    <div className="flex space-x-4 mt-2"> 
                      {/* Template Column */}
                   <div className="w-full">
                   <Label htmlFor="channel" className="mt-2 text-left">
                      Template
                    </Label>
                    <Input
                          type="text"
                          placeholder="Name your message template"
                          className="w-full h-[35px] border rounded-md p-2 mt-2 text-gray-500"
                          value={templateName}
                          onChange={handleInputChange} />
                {templateError && (
                    <p className="text-red-500 text-xs text-left mt-2 ml-2">{templateError}</p>
                  )}
                  </div >
                  {/* Language Column */}
                  <div className="w-full">   
                  <Label htmlFor="channel" className="mt-2 text-left">
                      Language
                    </Label>
                    {/* <Select value={language}
                    onValueChange={(value) => {
                    setLanguage(value);
                    console.log("Selected Language ID:", value); 
                  }} > 
                 
                  <SelectTrigger className='text-gray-500 w-48 mt-2'>
                  <SelectValue className='text-gray-500' placeholder={templateId?updateLanguage:"English"} />
                  </SelectTrigger >
                  <SelectContent>
                   {languageList.map((language) => (
                    <SelectItem className='text-gray-500' key={language.language_id} value={language.language_id as any}> 
                    {language.language_name} 
                    </SelectItem>
                    ))}
                  </SelectContent>
                </Select> */}

                 <Select onValueChange={(value) => setLanguage(value)}>
                <SelectTrigger className='text-gray-500 w-48 mt-2'>
                  <SelectValue className='text-gray-500' placeholder={templateId?updateLanguage:"English"} />
                </SelectTrigger>
                <SelectContent>
                  {languageList.map((type) => (
                    <SelectItem className='text-gray-500' key={type.language_id} value={type.language_id as any}>
                      {type.language_name}
                      {updateLanguage == type.language_name ? <CheckIcon /> : null}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

                {LanguageError && <p className="text-red-500 text-xs mt-2 ml-2">{LanguageError}</p>}
              </div>
              </div>

            </div> 


              <div className="border p-4 rounded-lg">
                <h2 className="mb-2 text-left" style={{fontWeight: 600 , fontSize: '16px' , paddingBottom: '10px'}}>Content</h2>
                <h5 className="text-md mb-2 text-left" style={{ fontWeight: 500 , fontSize: '14px'}}>Header 
                <span
      
                    style={{
                      background: '#F0F4F8', // Background color for the badge
                      color: '#64748B', // Fixed font color for testing
                      padding: '2px 10px', // Padding for the badge
                      borderRadius: '9999px', // Fully rounded badge
                      // Border style, matching the background
                      display: 'inline-flex', // Ensures correct alignment
                      alignItems: 'center', // Centers the text vertically
                      height: '20px', // Fixed height
                      marginLeft: '8px', // Space between title and badge
                      fontSize: '14px', // Optional: font size for better visibility
                      fontWeight: 600, // Set font weight for the badge text to bold
                    }}
                >Optional</span>
                </h5>
                <div className="space-y-4">
      
                    <Select value={headerType} 
                      onValueChange={handleOptionChange}
                  
                    // onValueChange={(value)=>{handleOptionChange(value);
                    //   console.log("LoadHeader:" + headerType)
                    // }}

                     >
                        <SelectTrigger className="w-full text-gray-500">
                        <SelectValue placeholder="None" />
                        </SelectTrigger>
                        <SelectContent>
                        <SelectItem value="image">Image</SelectItem>
                        <SelectItem value="text"> Text</SelectItem> 
                        <SelectItem value="document">Document</SelectItem>
                        <SelectItem value="video">Video</SelectItem>
                        </SelectContent>
                    </Select>
               
                    {/* Image, Video, or Document File Upload */}
                    {(selectedOption === 'image' || selectedOption === 'video' || selectedOption === 'document') && (
                        <div className="mt-4">
                        <Input
                            type="file"
                            accept={
                            selectedOption === 'image' ? 'image/jpeg, image/png' :
                            selectedOption === 'video' ? 'video/*' :
                            selectedOption === 'document' ? '.pdf, .doc, .docx, .xls, .xlsx' : ''
                            }
                            onChange={handleFileChange}
                            className="border rounded-md p-2 mb-4 w-full"
                        />
                        {selectedFile && (
                            <div className="mt-2 flex items-center">
                            <p>{selectedFile.name}</p>

                      <button
                      type="button"
                      onClick={handleRemoveFile}
                      className="text-black hover:text-gray-400"
                      aria-label="Delete File"
                    >
                      <FaTimes />
                    </button>

                  </div>
                )}
                 </div> )}

                    {/* Text Input */}
                    {selectedOption === 'text' && (
                        <div className="mt-4">
                        <Textarea
                            value={textInput}
                            onChange={handleTextChange}
                            className="text-gray-500 border rounded-md p-2 mb-4 w-full"
                            rows={4}
                            placeholder="Enter your text here"
                  />
                  </div>
                    )}
                    
                  <div>
                          <label className="block text-left mb-2" style={{ fontWeight: 500 ,fontSize: '14px' }}>Body</label>
                          <textarea 
                            className="w-full border rounded p-2 text-gray-500" 
                            rows={4} 
                            maxLength={1024}
                            value={bodyText}
                            placeholder="Hello"
                            // onChange={(e) => setBodyText(e.target.value)} 
                            onChange={(e) => handleBodyTextChange(e.target.value)}
                            >

                        </textarea>
                        
                        <Typography
                            variant="body2"
                            sx={{
                              color: bodyText.length < 25 ? "error.main" : "text.secondary",
                              mt: 1,
                              textAlign: "left",
                              fontFamily: "Salesforce Sans, sans-serif",
                            }}
                          >
                            {bodyText.length < 25 ? (
                              <p style={{ fontSize: "12px", fontFamily: "Salesforce Sans, sans-serif" }}>
                                The body text must contain at least 25 characters
                              </p>
                            ) : (
                              <p style={{ fontSize: "12px", fontFamily: "Salesforce Sans, sans-serif" }}>
                                Characters: {bodyText.length}/1024
                              </p>
                            )}
                          </Typography>

                  {BodyError && (<p className="text-red-500 text-sm">{BodyError}</p>)}
                  </div>
                  <Container>
                    <Box
                      sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      }}>
                    <Button
                        onClick={addVariable}
                        className="flex items-center px-4 py-2 border border-gray-300 rounded-md text-white font-thin hover:bg-gray-900 shadow-lg"
                        style={{ 
                          width: "150px", 
                          height: "40px", 
                          background: "#3A85F7", 
                          marginTop: "-8px",       
                          marginLeft: "-20px", 
                          fontWeight: "normal",
                        }}
                      >
                <span className="mr-1"> {/* Wrapper span with margin-right */}
                  <PlusIcon className="w-4 h-4" />
                </span>
              Add Variable
              <span className="ml-1"> {/* Wrapper span with margin-left */}
                <ChevronDownIcon className="w-4 h-4" />
              </span>
            </Button>

                 </Box>

                    <div style={{ textAlign: "left", marginTop: "25px" }}>
                        {boxes.map((box, i) => (
                        <div
                            key={i}
                            style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "15px",
                            }}
                        >
                            <Typography variant="body2" sx={{ color: "black", fontSize: "12px" }}>
                            Text box {i + 1}
                            </Typography>
                            <TextField
                            size="small"
                            type="text"
                            variant="standard"
                            sx={{ marginLeft: "10px", flex: 1 }} // You can keep using sx since you're using MUI
                            value={box.action}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => updateButtonAction(i, e)} // Specify the event type
                            />
                            <IconButton
                            onClick={() => onTextboxDelete(i)}
                            sx={{
                                color: "red",
                                borderRadius: "50%",
                                padding: "8px",
                                "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" },
                                marginLeft: "10px",
                            }}
                    >
                          <CancelIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </Container>
    
                        <div>
                            <label className="block text-left mb-2" style={{ fontWeight: 500 ,fontSize: '14px'}}>Footer 
                            <span
      
                            style={{
                              background: '#F0F4F8', 
                              color: '#64748B', 
                              padding: '2px 10px', 
                              borderRadius: '9999px', 
                              display: 'inline-flex', 
                              alignItems: 'center', 
                              height: '20px', 
                              marginLeft: '8px', 
                              fontSize: '14px', 
                              fontWeight: 600,
                            }}
                        >Optional</span>
                            </label>
                            {/* <input type="text" 
                            placeholder="Enter text" 
                            className="w-full border rounded p-2" 
                            value={FooterText}
                            onChange={(e) => setFooterText(e.target.value)}
                            /> */}
                            
                      <Select
                         value={FooterText}
                         onValueChange={handleFooterChange}
                      > 
                      <SelectTrigger className="w-full border rounded p-2 text-gray-500">
                        <SelectValue placeholder="Select an option" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Thank You">Thank You</SelectItem>
                        <SelectItem value="Welcome">Welcome</SelectItem>
                      </SelectContent>
                    </Select>

                        </div>
                        </div>
                    </div>
      <Card
         className="border p-4 rounded-lg mr-4">
         {/* className="bg-white rounded-lg p-4 border border-gray-300 shadow-none w-[600px] mb-24"> */}

              <Typography
                  variant="h6"
                  style={{
                      fontSize: "16px",
                      color: "black",
                      textAlign: "left",
                      fontWeight: "bold",
                      marginBottom: "8px",
                  }}
              >
                    Buttons
                 <span
                    style={{
                    background: "#F0F4F8",
                    color: "#64748B",
                    padding: "2px 10px",
                    borderRadius: "9999px",
                    display: "inline-flex",
                    alignItems: "center",
                    height: "20px",
                    marginLeft: "8px",
                    fontSize: "14px",
                    fontWeight: 600,
                }}
            >
                Optional
            </span>
        </Typography>

        <div className="inline-block mt-4 ml-4 w-full flex-col justify-start">
            <Button
                onClick={handleAddRow}
                className="flex items-center px-4 py-2 border border-gray-300 font-thin rounded-md text-white hover:bg-gray-900 shadow-lg"
                style={{
                    width: "150px",
                    height: "40px",
                    background: "#3A85F7",
                    marginTop: "-8px",
                    marginLeft: "-8px",
                    fontWeight: "normal",
                }}
            >
                <span className="mr-1">
                    <PlusIcon className="w-4 h-4" />
                </span>
                Add button
                <span className="ml-1">
                    <ChevronDownIcon className="w-4 h-4" />
                </span>
            </Button>

            {warning && (
                <p className="text-red-500 text-sm mt-2">{warning}</p>)}

            {/* Headings Row */}
            {rows.length > 0 && (
                <div
                    className="flex w-full mt-4"
                    style={{ justifyContent: "flex-start" }}
                >
                <div className="w-[120px] pr-4 block text-sm font-medium ml-[2]">
                    <label className="w-full font-bold text-center">
                        Type of action
                    </label>
                </div>
                <div className="w-[120px] pr-4 block text-sm font-medium">
                    <label className="w-full font-bold text-center">
                        Button text
                    </label>
                </div>
                <div className="w-[120px] pr-4 block text-sm font-medium ml-3">
                    <label className="w-full font-bold text-center">
                        URL ypte
                    </label>
                </div>
                <div className="w-[120px] pr-4 block text-sm font-medium ml-6">
                    <label className="w-full font-bold text-center">
                        Website URL
                    </label>
                </div>
            </div>
        )}

              {/* Rows of Input Fields */}
                  <div className="mt-4">
                    {rows.map((row, index) => (
                  <div
                    key={index}
                    className="mb-4 flex w-full"
                    style={{ justifyContent: "flex-start" }}
                    >
                    <div className="w-[140px] pr-4 block">
                    
                <Select
                  value={row.buttonType} 
                  onValueChange={(value) => handleRowInputChange(index, "buttonType", value)}>
                  
                  <SelectTrigger className="text-gray-500">
                    <SelectValue
                      className="text-gray-500"
                      placeholder={ "Select Action" } 
                      />
                  </SelectTrigger>
                <SelectContent>
              {buttonList.map((button) => (
                <SelectItem key={button.button_id} value={button.button_type}>
                  {button.button_type}
                </SelectItem>
              ))}
            </SelectContent>
                 </Select>

                </div>
                <div className="w-[140px] pr-4">
                    <input
                        type="text"
                        className="block w-full px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                        style={{
                            width: "110px",
                            height: "40px",
                            marginRight: "10px",
                            padding: "5px",
                        }}
                        value={row.buttonText}
                        onChange={(e) => handleRowInputChange(index, "buttonText", e.target.value)}
                        placeholder={
                            row.buttonType === "Phone number"
                                ? "Enter phone number"
                                : "Enter button text"
                        }
                        disabled={row.buttonType === "Phone number"}
                    />
                </div>

                        <div className="w-[140px] pr-4">
                            <select
                                className="py-2 px-3 border border-gray-300 rounded-md"
                                style={{
                                    width: "110px",
                                    height: "40px",
                                    marginRight: "10px",
                                    padding: "5px",
                                    fontSize: "12px",
                                }}
                                value={row.buttonTypeDropdown}
                                onChange={(e) =>
                                    handleRowInputChange(index, "buttonTypeDropdown", e.target.value)
                                }
                            >
                                <option value="static">Static</option>
                                <option value="dynamic">Dynamic</option>
                            </select>
                        </div>


                        {/* Website URL Input */}
                        <div className="w-[140px]">
                            <input
                                type="text"
                                className="block w-full px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                                style={{
                                    width: "110px",
                                    height: "40px",
                                    marginRight: "10px",
                                    padding: "5px",
                                }}
                                value={row.websiteUrl}
                                onChange={(e) => handleRowInputChange(index, "websiteUrl", e.target.value)}
                                placeholder={
                                    row.buttonType === "Phone number"
                                        ? "Enter phone number"
                                        : "Enter website URL"
                                }
                                pattern={
                                    row.buttonType === "Phone number"
                                        ? "\\d*" // Only numbers allowed for phone numbers
                                        : undefined
                                }
                            />
                        </div>
                    </div>
                ))}

                <div className=''> {/* Apply negative top margin here */}
                    <p className="text-gray-600 text-left ml-[-10px] mt-4 leading-5" style={{ width: "450px", fontSize: "0.875rem", lineHeight: "1.4rem" }}>
                        Create buttons that let customers respond to your message or take action.
                        You can add up to ten buttons. If you add more than three buttons, they
                        will appear in a list.
                    </p>
                </div>
            </div>
        </div>
    </Card>
     </div>

     <div className="border justify-items-center pl-7 pr-7 h-[720px] rounded-lg">
              <h2 className="mb-2 mt-4 ml-[-200px] font-bold">Template preview</h2>
              <div className="flex flex-col justify-between rounded-[50px] text-black p-4 w-[350px] min-h-[640px]"  style={{ backgroundColor: '#f9f9f9'}} >
                <div className="justify-center">
                  <i className="fas fa-mobile-alt text-4xl mb-4"></i>
                  {renderPreview()}
                </div>
                  </div>
                </div>
            </div>  
            </div>
        </div>
    );
};

export default CreateTemplate;
