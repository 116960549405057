import React, {
  FC,
  useState,
  useRef,
  ChangeEvent,
  FormEvent,
  useEffect,
} from "react";
import "./CSS/LoginPage.css";
import Logo from "../Assets/Logo.svg";
import LoginImage from "./../Assets/LoginBackground.png";
import loginVideo from "../Assets/loginVideo.mp4";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Typography, Box } from "@mui/material";
import { Input } from "../Components/ui/input";
import { Button } from "../Components/ui/button";
import { Label } from "../Components/ui/label";
// import { ToastContainer, toast } from "react-toastify";
import config from "../config.json";
import { CheckIcon } from "@radix-ui/react-icons";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Components/ui/select";
import "../Styles/globals.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { UseSelector } from "react-redux";
import { setworkspace } from "../State/slices/AuthenticationSlice";
import { setCloseAddWorkspaceDialog } from "../State/slices/AdvertiserAccountSlice";
import { RootState } from "../State/store";
import { useToast } from "../Components/ui/use-toast";
import { Toaster } from "../Components/ui/toaster";

interface CustomWorkspaceControlProps {
  setNext: React.Dispatch<React.SetStateAction<boolean>>; // Type for setNext
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomWorkspaceControl: FC<CustomWorkspaceControlProps> = ({
  setNext,
  setAuthenticated,
}) => {
  const [PageLoad, setPageLoad] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [CompanyNameError, setFirstNameError] = useState<string | null>(null);

  const [billingCountry, setBillingCountry] = useState("United Arab Emirates"); // Default is "United Arab Emirates"


  const [selectedIndustry, setSelectedIndustry] = useState("Tourism");


  const [selectedWorkspaceType, setSelectedWorkspaceType] = useState("Advertiser");//Default to "Advertiser"


  const [fileName, setFileName] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const location = useLocation();
  const email = location.state?.email || "";
  const [apiUrl, setApiUrl] = useState("");
  const [authapiUrl, setAuthApiUrl] = useState("");
  const [fileError, setFileError] = useState("");
  const [LoginState, SetLoginState] = React.useState<{
    UserName: string;
    Password: string;
  }>({ UserName: "Arul", Password: "arul@123" });

  const [countries, setCountries] = useState<{ id: number; label: string }[]>([]);
  const [industries, setIndustries] = useState<{ id: number; label: string }[]>([]);
  const [workspace_types, SetWorkspaceTypes] = useState<{ id: number; label: string }[]>([]);




  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const signupData = useSelector(
    (state: RootState) => state.authentication.signupData
  );
  const personalData = useSelector(
    (state: RootState) => state.authentication.userData
  );
  const addWorkspaceFromDropdown = useSelector(
    (state: RootState) => state.advertiserAccount.addWorkspaceFromDropdown
  );




const toast = useToast();


useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((config) => {
        //console.log("config :", config);
        setApiUrl(config.API_URL); // Set the API URL from config
        // setAuthApiUrl(config.API_URL);// set the Auth URL from config
      })
      .catch((error) => console.error("Error loading config:", error));
  }, []);





 // Fetch domain list from API
 useEffect(() => {
  if (apiUrl) {
    getCountriesList();
    getIndustriesList();
    getWorkspaceTypes();
  }
}, [apiUrl]);



const getCountriesList = async (): Promise<void> => {
  try {
    const response = await axios.get(`${apiUrl}/GetCountryList`); 
    console.log("Response:", response.data);

    if (response.data && response.data.countryList) {
      // Transform the response into the desired format
      const countries = response.data.countryList.map((country: { country_id: number; country_name: string }) => ({
        id: country.country_id,
        label: country.country_name,
      }));

      console.log("Transformed Countries List:", countries);
      // Use the transformed list as needed, e.g., save it to state
      setCountries(countries); // Assuming you have a setCountries function for state
    } else {
      console.log("No countries available in response.");
    }
  } catch (error) {
    console.error("Error fetching countries list:", error);
  }
};

// Function to fetch industries and transform data
const getIndustriesList = async (): Promise<void> => {
  try {
    const response = await axios.get(`${apiUrl}/GetIndustryList`); 
    console.log("Response:", response.data);

    if (response.data && response.data.industryList) {
      // Transform the response into the desired format
      const industries = response.data.industryList.map((industry: { industry_id: number; industry_name: string }) => ({
          id: industry.industry_id,
          label: industry.industry_name,
        }));

      console.log("Transformed Industries List:", industries);
      setIndustries(industries); // Save the transformed list to state
    } else {
      console.log("No industries available in response.");
    }
  } catch (error) {
    console.error("Error fetching industries list:", error);
  }
};

// Function to fetch industries and transform data
const getWorkspaceTypes = async (): Promise<void> => {
  try {
    const response = await axios.get(`${apiUrl}/GetWorkspaceTypesList`); 
    console.log("Response:", response.data);

    if (response.data && response.data.workspaceTypes) {
      // Transform the response into the desired format
      const workspace_types_data = response.data.workspaceTypes.map((workspace_type: { workspace_id: number; workspace_name: string }) => ({
          id: workspace_type.workspace_id,
          label: workspace_type.workspace_name,
        }));

      console.log("Transformed Workspace_Type List:", workspace_types_data);
      SetWorkspaceTypes(workspace_types_data); // Save the transformed list to state
    } else {
      console.log("No workspace_types available in response.");
    }
  } catch (error) {
    console.error("Error fetching workspace_types list:", error);
  }
};




  const validateName = (value: string): boolean => {
    const regex = /^[a-zA-Z][a-zA-Z0-9\s_-]*$/;
    return regex.test(value);
  };

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (validateName(value)) {
      setCompanyName(value); // Set state if valid
      setFirstNameError(null); // Clear error
    } else {
      setFirstNameError(
        "First character should be a letter('-',' ','_' are allowed between words)"
      ); // Set error message
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/png"];
      const maxFileSize = 5 * 1024 * 1024; // 5MB
      if (!validFileTypes.includes(file.type)) {
        setFileError("Please select a valid image (PNG or JPEG).");
        setFileName("");
      } else if (file.size > maxFileSize) {
        setFileError("File size should not exceed 5MB.");
        setFileName("");
      } else {
        setFileError("");
        setFileName(file.name);

        // Convert image to Base64
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result?.toString().split(",")[1]; // Remove the metadata
          setBase64Image(base64String || "");
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFileError("Please select a image");
    }
  };

  const handleAddWorkspaceFromDropdown = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
        //for data from +AddWorkspace from dashboard dropdown
        const dialogData = {                        
          email: email, // Placeholder email, update it dynamically
          workspaceName: companyName, // Company name state
          billingCountry: (billingCountry === "United Arab Emirates")?"14":billingCountry, // Billing country state
          workspaceIndustry: (selectedIndustry === "Tourism")?"11":selectedIndustry, // Industry state
          workspaceType: (selectedWorkspaceType === "Advertiser")?"1":selectedWorkspaceType, // Workspace type state
          status: "Active", // Static 'Active' status
          createdBy: 1, // Example user ID, set dynamically
          createdDate: new Date().toISOString(), // Current date
          updatedBy: 1, // Example user ID, set dynamically
          updatedDate: new Date().toISOString(), // Current date
          mappingId: 0, // Adjust or set as necessary
          base64Image: base64Image, // Base64 image from file input
        };

        console.log("Dialog data: " + JSON.stringify(dialogData, null, 2));

        try{

          const response = await axios.post(
            `${apiUrl}/InsertWorkspaceInfo`,
            dialogData
            );
    
            if (response.data.status === "Success") {
              toast.toast({
                title:"Success",
                description:"Created Workspace"
              })
              const Close=()=>{
                dispatch(setCloseAddWorkspaceDialog(true));
                const path = companyName;
                dispatch(setworkspace(companyName));
                setAuthenticated(true);
                navigate("/navbar/dashboard", { state: {path , email } });
              }
              Close();
            }
            else{
              const Close=()=>{
                dispatch(setCloseAddWorkspaceDialog(true));
              }
              Close();
              toast.toast({
                title:"Error",
                description:"Failed to Create Workspace"
              })
            }
        }
        catch(error){
          const Close=()=>{
            dispatch(setCloseAddWorkspaceDialog(true))
          }
          Close();
          toast.toast({
            title:"Error",
            description:"Failed to Create Workspace"
          })
        }
    
  }

  useEffect(()=>{console.log("addworkspace: "+addWorkspaceFromDropdown)})


  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Prepare the payload

    

    

    const payload = {
      email: email, // Placeholder email, update it dynamically
      workspaceName: companyName, // Company name state
      billingCountry: (billingCountry === "United Arab Emirates")?"14":billingCountry, // Billing country state
      workspaceIndustry: (selectedIndustry === "Tourism")?"11":selectedIndustry, // Industry state
      workspaceType: (selectedWorkspaceType === "Advertiser")?"1":selectedWorkspaceType, // Workspace type state
      status: "Active", // Static 'Active' status
      createdBy: 1, // Example user ID, set dynamically
      createdDate: new Date().toISOString(), // Current date
      updatedBy: 1, // Example user ID, set dynamically
      updatedDate: new Date().toISOString(), // Current date
      mappingId: 0, // Adjust or set as necessary
      base64Image: base64Image, // Base64 image from file input
    };

    // dispatch(setWorkspaceData(payload));
    dispatch(setworkspace(companyName));
    console.log("signupdata: " + signupData);
    console.log("personaldata: " + personalData);
    console.log("workspacedata: ", payload);
    try {
      const signUpResponse = await axios.post(
        `${apiUrl}/UserRegister`,
        signupData
      );
      console.log("res: " + signUpResponse);
      if (signUpResponse.data[0].Status === "Success") {
        const personalResponse = await axios.post(
          `${apiUrl}/InsertUserPersonalInfo`,
          personalData
        );
        if (personalResponse.data.status === "Success") {
          const response = await axios.post(
            `${apiUrl}/InsertWorkspaceInfo`,
            payload
          );
          if (response.data.status === "Success") {
            //workspace success

            let path = "";
            const Close = async() => {
              
                const WorkspaceName = await axios.get(
                  `${apiUrl}/GetWorkspaceNameByEmail?EmailId=${email}`
                );

                const workspace_type = WorkspaceName.data.workspaceName.workspace_type;
                // console.log(WorkspaceName.data.workspaceName.workspace_name);
                //const path = WorkspaceName.data.workspaceName.workspace_name;
                if (
                  WorkspaceName.status === 200 &&
                  WorkspaceName.data.status === "Success"
                ) {

                  path = WorkspaceName.data.workspaceName.workspace_name;
                } else {
                  path = "Admin";
                }

                const userWorspaceRole ={
                  Mode:'InsertAdmin',
                  EmailId: email,
                }
                const UserWorkspaceRoleResponse = await axios.post(
                  `${apiUrl}/InsertUserWorkspaceRole`,
                  userWorspaceRole
                );

                if (
                  UserWorkspaceRoleResponse.status === 200 &&
                  UserWorkspaceRoleResponse.data.status === "Success"
                ) {
                  setAuthenticated(true);
                    navigate("/navbar/dashboard", { state: { path, email } });
                    setNext(true);
                  
                } else {
                  toast.toast({
                    title: "Error",
                    description: "Failed to create role for user. Please try again.",
                  })
                }
              }
            Close();
            toast.toast({
              title:"SignUp Successful",
              description:"You have successfully signed up for our platform. Please check your email for verification link.",
            })
          } else {
            //workspace failed
            toast.toast({
              title: "Error",
              description: "Failed to create workspace. Please try again.",
            })
          }
        } else {
          //for personaldata post failure
          toast.toast({
            title: "Error",
            description: "Failed to create personal data. Please try again.",
          })
        }
      } else {
        //for signupdata post failure
        toast.toast({
          title: "Error",
          description: "Failed to create user. Please try again.",
        })
      }
    } catch (error) {
      toast.toast({
        title: "Error",
        description: "Failed to create user. Please try again.",
      })
      console.error("Network error:", error);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };


 

  return (
    <>
      {PageLoad && (
        <>
          <p>Loading</p>
          <div className="page-preloader"></div>
        </>
      )}
      {!PageLoad && (
(!addWorkspaceFromDropdown)?(<>
<Container maxWidth="xs" sx={{ padding: 2 }}>
          <Toaster />
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            <Label
              htmlFor="pageprogress"
              className="whitespace-nowrap text-left"
              style={{ fontSize: "14px", color: "#71717A" , fontWeight: 400 }}
            >
              2/2
            </Label>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "left", fontWeight: 600, fontSize: "24px", color: "#09090B", marginBottom: 0 }}
            >
              Create your workspace
            </Typography>

            <div className="grid w-full max-w-md items-start gap-1.5 mt-1">
              <Label
                htmlFor="companyLogo"
                className="whitespace-nowrap text-left"
                style={{ fontSize: "14px", marginTop: "2rem" , color: "#020617" }}
              >
                Company logo
              </Label>
              <div className="flex w-full items-center gap-2">
                <Input
                  type="text"
                  value={fileName}
                  readOnly
                  className="flex-grow w-[80%] border-[#E2E8F0] placeholder-[#64748B]"
                  placeholder="Choose File   No file chosen"
                  style={{ fontWeight: 500 }}
                />
                <Button
                  type="button"
                  onClick={handleUploadClick}
                  className="bg-[#F1F5F9] w-[30%] text-gray-700 hover:bg-gray-300 focus:ring-gray-400 h-8.1 mt--1"
                  style={{
                    height: "2.2rem",
                    padding: "0 0.5rem",
                    fontSize: "0.8rem",
                  }}
                >
                  Upload image
                </Button>
              </div>
              <input
                ref={fileInputRef}
                id="companyLogo"
                type="file"
                accept="image/*"
                className="sr-only"
                onChange={handleFileChange}
              />
              <p
                className="text-sm text-gray-500"
                style={{ fontSize: "13px", marginTop: "0.2rem" , color:"#64748B" }}
              >
                * .png, * .jpeg files up to 5MB at least 400px by 400px
              </p>
            </div>

            <div className="grid w-full max-w-md items-start gap-1.5 mt-2">
              <Label
                htmlFor="companyname"
                className="whitespace-nowrap text-left "
                style={{ fontSize: "14px", marginTop: "0.7rem", color: "#020617" }}
              >
                Company Name 
              </Label>
              <Input
                required
                id="companyname"
                placeholder="Enter your company name.."
                className="w-full border-gray-200 placeholder:font-normal placeholder:text-[#64748B] rounded-[7px] custom-placeholder"
                onChange={handleFirstNameChange}
              />
              {CompanyNameError && (
                <p className="text-red-500 text-sm">{CompanyNameError}</p>
              )}
            </div>

            <div className="grid w-full max-w-md items-start gap-1.5 mt-2">
              <Label
                htmlFor="billingcountry"
                className="whitespace-nowrap text-left"
                style={{ fontSize: "0.8rem", marginTop: "0.9rem", color: "#020617" }}
              >
                Billing Country
              </Label>
              <Select
                onValueChange={(value) => {
                  console.log("Raw Value from Dropdown:", value); // Log the raw value
                  setBillingCountry(value); // Update state
                }}
              >
                <SelectTrigger className="w-full border-gray-200 relative">
                  <SelectValue placeholder={billingCountry} />
                </SelectTrigger>
                <SelectContent className="absolute z-10 top-full max-h-60 overflow-auto">
                {countries.map((country) => (
                    <SelectItem key={country.id} value={country.id.toString()}>
                      {/* Added flex container for alignment */}
                      <div className="flex items-center w-full">
                      <span>{country.label}</span>
                      {(billingCountry === country.label) && <CheckIcon className="ml-2"/>}
                     </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>


            <div className="grid w-full max-w-md items-start gap-1.5 mt-2">
              <Label
                htmlFor="companyindustry"
                className="whitespace-nowrap text-left"
                style={{ fontSize: "0.8rem", marginTop: "0.9rem", color: "#020617" }}
              >
                Company Industry
              </Label>
              <Select
                onValueChange={(value) => {
                  console.log("Raw Value from Dropdown:", value); // Log the raw value
                  setSelectedIndustry(value); // Update state
                }}
              >
                <SelectTrigger className="w-full border-gray-200 relative">
                  <SelectValue
                  placeholder={selectedIndustry} />
                </SelectTrigger>
                <SelectContent className="absolute z-10 top-full max-h-40 overflow-auto">
                  {industries.map((data) => (
                    <SelectItem key={data.id} value={data.id.toString()}>
                      {/* Added flex container for alignment */}
                      <div className="flex items-center w-full">
                        <span>{data.label}</span>
                        {(selectedIndustry === (data.label)) && <CheckIcon className="ml-2" />}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="grid w-full max-w-md items-start gap-1.5 mt-2">
              <Label
                htmlFor="workspacetype"
                className="whitespace-nowrap text-left"
                style={{ fontSize: "0.8rem", marginTop: "0.9rem", color: "#020617" }}
              >
                Workspace Type
              </Label>
              <Select
                onValueChange={(value) => {
                  console.log("Raw Value from Dropdown:", value); // Log the raw value
                  setSelectedWorkspaceType(value); // Update state
                }}
              >
                <SelectTrigger className="w-full border-gray-200 relative">
                  <SelectValue
                  placeholder={selectedWorkspaceType} />
                </SelectTrigger>
                <SelectContent className="absolute z-10  max-h-20 overflow-auto">
                  {workspace_types.map((data) => (
                    <SelectItem key={data.id} value={data.id.toString()}>
                      {/* Added flex container for alignment */}
                      <div className="flex items-center w-full">
                        <span>{data.label}</span>
                        {(selectedWorkspaceType === (data.label)) && <CheckIcon className="ml-2" />}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <Button type="submit" className="w-full mt-2 bg-blue-600"
            style={{
              padding: "0.4rem",
              height: "2rem",
              backgroundColor: "#007AFF", 
              fontSize: "14px", 
              color: "#FAFAFA", 
              fontWeight: 500,
            }}>
              Complete
            </Button>
          </Box>
        </Container>
</>):(
          <>
          
          <Toaster />
          <Box
            component="form"
            onSubmit={handleAddWorkspaceFromDropdown}
            sx={{ display: "flex", flexDirection: "column" }}
            className="space-y-6"
          >

            <div className="grid w-full items-start gap-1.5 mt-1">
              <Label
                htmlFor="companyLogo"
                className="whitespace-nowrap text-left"
              >
                Company logo
              </Label>
              <div className="flex w-full items-center gap-2">
                <Input
                  type="text"
                  value={fileName}
                  readOnly
                  className="flex-grow w-[80%] border-gray-400"
                  placeholder="Choose File   No file chosen"
                />
                <Button
                  type="button"
                  onClick={handleUploadClick}
                  className="bg-gray-200 w-[30%] text-gray-700 hover:bg-gray-300 focus:ring-gray-400 h-8.1 mt--1"
                >
                  Upload image
                </Button>
              </div>
              <input
                ref={fileInputRef}
                id="companyLogo"
                type="file"
                accept="image/*"
                className="sr-only"
                onChange={handleFileChange}
              />
              <p
                className="text-sm text-gray-500"
                style={{ fontSize: "0.78rem", marginTop: "0.2rem" }}
              >
                * .png, * .jpeg files up to 5MB at least 400px by 400px
              </p>
            </div>

            <div className="grid w-full items-start gap-1.5 mt-2">
              <Label
                htmlFor="companyname"
                className="whitespace-nowrap text-left"
              >
                Company Name <span className="text-red-500">*</span>
              </Label>
              <Input
                required
                id="companyname"
                placeholder="Enter your company name.."
                className="w-full border-gray-400 rounded-[7px] custom-placeholder"
                onChange={handleFirstNameChange}
              />
              {CompanyNameError && (
                <p className="text-red-500 text-sm">{CompanyNameError}</p>
              )}
            </div>

            <div className="grid w-full items-start gap-1.5 mt-2">
              <Label
                htmlFor="billingcountry"
                className="whitespace-nowrap text-left"
              >
                Billing Country <span className="text-red-500">*</span>
              </Label>
              <Select
                onValueChange={(value) => {
                  console.log("Raw Value from Dropdown:", value); // Log the raw value
                  setBillingCountry(value); // Update state
                }}
              >
                <SelectTrigger className="w-full border-gray-200 relative">
                  <SelectValue
                  placeholder={billingCountry} />
                </SelectTrigger>
                <SelectContent className="absolute z-10 top-full max-h-60 overflow-auto">
                  {countries.map((country) => (
                    <SelectItem key={country.id} value={country.id.toString()}>
                      {/* Added flex container for alignment */}
                      <div className="flex items-center w-full">
                        <span>{country.label}</span>
                        {(billingCountry === (country.label)) && <CheckIcon className="ml-2" />}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="grid w-full max-w-md items-start gap-1.5 mt-2">
              <Label
                htmlFor="companyindustry"
                className="whitespace-nowrap text-left"
                style={{ fontSize: "0.8rem", marginTop: "0.9rem", color: "#020617" }}
              >
                Company Industry
              </Label>
              <Select
                onValueChange={(value) => {
                  console.log("Raw Value from Dropdown:", value); // Log the raw value
                  setSelectedIndustry(value); // Update state
                }}
              >
                <SelectTrigger className="w-full border-gray-200 relative">
                  <SelectValue
                  placeholder={selectedIndustry} />
                </SelectTrigger>
                <SelectContent className="absolute z-10 top-full max-h-40 overflow-auto">
                  {industries.map((data) => (
                    <SelectItem key={data.id} value={data.id.toString()}>
                      {/* Added flex container for alignment */}
                      <div className="flex items-center w-full">
                        <span>{data.label}</span>
                        {(selectedIndustry === (data.label)) && <CheckIcon className="ml-2" />}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="grid w-full items-start gap-1.5 mt-2">
              <Label
                htmlFor="workspacetype"
                className="whitespace-nowrap text-left"
              >
                Workspace Type
              </Label>
              <Select
                onValueChange={(value) => {
                  console.log("Raw Value from Dropdown:", value); // Log the raw value
                  setSelectedWorkspaceType(value); // Update state
                }}
              >
                <SelectTrigger className="w-full border-gray-200">
                  <SelectValue
                  placeholder={selectedWorkspaceType} />
                </SelectTrigger>
                <SelectContent className="absolute z-10 top-full max-h-20 overflow-auto">
                  {workspace_types.map((data) => (
                    <SelectItem key={data.id} value={data.id.toString()}>
                      {/* Added flex container for alignment */}
                      <div className="flex items-center w-full">
                        <span>{data.label}</span>
                        {(selectedWorkspaceType === (data.label)) && <CheckIcon className="ml-2" />}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <Button type="submit" className="w-full mt-2 bg-blue-600">
              Complete
            </Button>
          </Box>
        
          </>
        )
        
      )}
    </>
  );
};

const Workspace: FC = () => {
  return (
    <div className="login-page" style={{ display: "flex", height: "100vh" }}>
      <div
        style={{
          position: "fixed",
          top: "30px",
          left: "30px",
          zIndex: 20,
        }}
      >
        <img src={Logo} alt="Logo" style={{ width: "170px", height: "auto" }} />
      </div>
      <div
        className="left-pane"
        style={{
          flex: '1',
          // backgroundImage: `url(${figmaPageImage})`,
          // backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          position: 'relative',
          overflow: 'hidden'
        }}
      
      >
         <video autoPlay loop muted playsInline className="background-video"
         style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'fill'}}>
          <source src={loginVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
       </div>
      <div
        className="right-pane"
        style={{
          flex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="LoginMatCard"
          style={{ width: "90%", maxWidth: "400px" }}
        >
          {/* <CustomWorkspaceControl /> */}
        </div>
      </div>
    </div>
  );
};

export default Workspace;
