import React, { useState, useEffect } from "react";
import { Button } from "../../Components/ui/button";
import { Input } from "../../Components/ui/input";
import { Badge } from "../../Components/ui/badge";
import {
  DotsHorizontalIcon,
  CaretSortIcon,
} from "@radix-ui/react-icons";


import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/ui/table";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "../../Components/ui/breadcrumb";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../Components/ui/dropdown-menu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  PlayIcon,
  PauseIcon,
  StopwatchIcon,
  MagnifyingGlassIcon,
  CheckIcon,
} from "@radix-ui/react-icons";
import { Skeleton } from "../../Components/ui/skeleton";
import DropdownMenuDemo from "../../Components/Filter/AccountsDropdown";
import { useDispatch } from "react-redux";
import { setCreateBreadCrumb } from "../../State/slices/AdvertiserAccountSlice";

interface Account {
  accountId: number;
  name: string;
  email: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

type AccountCheck = {
  accountId: number;
  name: string;
};


//
const Accounts: React.FC = () => {
  const [openMenuRowId, setOpenMenuRowId] = useState<number | null>(null);

  const [accountList, setAccountList] = useState<Account[]>([]);
  const [currentAccounts, setCurrentAccounts] = useState<Account[]>([]);


  const navigate = useNavigate();


  const [isSorted, setIsSorted] = useState(false);
  const [originalAccounts, setOriginalAccounts] = useState(currentAccounts);

  const [checkboxSelectedRows, setCheckboxSelectedRows] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5); // Default 5 rows per page
  const [searchTerm, setSearchTerm] = useState("");
  const [apiUrlAdminAcc, setapiUrlAdminAcc] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const [filterData, setFilterData] = useState({
    filter: "All Accounts",
    value: 0,
  });

  
  const [hasAccounts, setHasAccounts] = useState(false);
  const dispatch = useDispatch();

  

  const handleCheckboxRowSelect = (id: number) => {
    setCheckboxSelectedRows((prev) => {
      const newSelectedRows = prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id];
      setIsAllSelected(newSelectedRows.length === currentAccounts.length); 
      return newSelectedRows;
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setCheckboxSelectedRows([]);
    } else {
      const allIds = currentAccounts.map((account) => account.accountId);
      setCheckboxSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const config = await response.json();
        setapiUrlAdminAcc(config.ApiUrlAdminAcc);
        console.log("apiUrlAdminAcc:" , apiUrlAdminAcc);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (apiUrlAdminAcc) {
      getAccountList();
    }
  }, [apiUrlAdminAcc]); // Runs when apiUrlAdminAcc is updated



  const filteredAccounts = accountList.filter((account) => {
    const matchesSearchTerm = searchTerm
      ? account.name.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
  
    const matchesTypeFilter =
      filterData.filter === "All Accounts" || account.type === filterData.filter;
  
    return matchesSearchTerm && matchesTypeFilter;
  });
  
  

  // Calculate total pages for filtered accounts
  const totalPages: number = Math.ceil(filteredAccounts.length / rowsPerPage);

  useEffect(() => {
    const newCurrentAccounts = filteredAccounts.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );

    setCurrentAccounts(newCurrentAccounts);
  }, [filterData, accountList, currentPage, rowsPerPage, searchTerm]);
  // Only re-run if dependencies change

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };





 

  // Function to handle the actual delete after confirmation

  useEffect(() => {
    console.log(
      "filter data: " +
        filterData.filter +
        " " +
        filterData.value
    );
  }, [filterData]);



  const getAccountList = async () => {
    setIsLoading(true);
    try {
      console.log("AccountGet")
      const response = await axios.get(`${apiUrlAdminAcc}/GetAccountList`);
      console.log( "Response : " , response.data.accountList);
      if (response.data && response.data.accountList) {
        setAccountList(response.data.accountList);
        setIsLoading(false);
        console.log("account List : ", response.data.accountList);
      } else {
        console.log("No account list available in response.");
        setIsLoading(false);
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching account list:", error);
    } finally {
      // Ensure the menu is closed after fetching data
    }
  };

 

  const handleMenuToggle = (rowId: number) => {
    setOpenMenuRowId(openMenuRowId === rowId ? null : rowId);
  };

  const sortAccountList = (tableHeader: string) => {
    const sortByField = (
      field: keyof (typeof currentAccounts)[0],
      type: "string" | "number" | "date" = "string"
    ) => {
      const sortedAccounts = [...currentAccounts].sort((a, b) => {
        if (type === "number") {
          return Number(a[field]) - Number(b[field]);
        } else if (type === "date") {
          return (
            Date.parse(a[field] as string) - Date.parse(b[field] as string)
          );
        } else {
          return String(a[field]).localeCompare(String(b[field]));
        }
      });
      setOriginalAccounts(currentAccounts);
      setCurrentAccounts(sortedAccounts);
    };

    if (isSorted) {
      setCurrentAccounts(originalAccounts);
    } else {
      switch (tableHeader) {
        case "ByAccountName":
          sortByField("name", "string");
          break;
        case "ByAccountEmail":
          sortByField("email", "string");
          break;
        case "ByAccountType":
          sortByField("type", "string");
          break;
        case "ByAccountCreatedAt":
          sortByField("createdAt", "date"); // Sorting by start date
          break;
        case "ByAccountUpdatedAt":
          sortByField("updatedAt", "date"); // Sorting by start date
          break;

        default:
          console.warn("Unknown table header");
      }
    }

    setIsSorted(!isSorted);
    console.log("Sorted accounts:", currentAccounts); // For debugging
  };


  useEffect(() => {
    setHasAccounts(accountList.length > 0);
  }, [accountList]);
  // const hasAccounts = accountList.length > 0;

  const handleView = (accountId: number) => {
    console.log(`View account with ID: ${accountId}`);
  };
  
  const handleImpersonate = (accountId: number) => {
    console.log(`Impersonating account with ID: ${accountId}`);
  };
  
  const handleDeleteClick = (accountId: number) => {
    console.log(`Deleting account with ID: ${accountId}`);
  };
  

  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div>
          <div className="flex  mt-4">
            <Skeleton className="w-[350px] h-10 mb-4" />{" "}
            {/* Skeleton for search input */}
            <div className="flex items-end ml-auto">
              {/* <Skeleton className="w-32 h-10 mb-4 ml-4" />{" "} */}
              {/* Skeleton for date picker */}
              <Skeleton className="w-32 h-10 mb-4 ml-4" />{" "}
              {/* Skeleton for filter button */}
              {/* <Skeleton className="w-32 h-10 mb-4 ml-4" />{" "} */}
              {/* Skeleton for export button */}
            </div>
          </div>

          {/* Table Skeleton */}
          <div className="rounded-md border">
            <Table className="rounded-xl border-gray-200">
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <Skeleton className="w-full h-6" />{" "}
                    {/* Skeleton for table header */}
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Array.from({ length: 5 }).map((_, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>{" "}
                    {/* Skeleton for table cell */}
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
      <div className="fixed flex justify-end items-end right-0 top-[-15px] z-20 p-4">
        
      </div>

      {hasAccounts ? (
        <div>
          {/* Existing table code here */}
          <div className="flex  mt-2">
            <div className=" ">
              <Input
                placeholder="Search account by name..."
                className="w-[350px] text-[14px] font-normal text-[#64748B]"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className=" ">
            {/* Filter Button */}
             <DropdownMenuDemo setFilterData={setFilterData} />
            </div>
          </div>

          <div className="rounded-md border">
            <Table
              className="rounded-xl whitespace-nowrap border-gray-200  "
              style={{ color: "#020202", fontSize: "15px" }}
            >
              <TableHeader className="text-center text-[14px] font-medium">
                <TableRow>
                  <TableHead className="">
                    <div className="flex items-center gap-6 justify-start cursor-pointer">
                      <input
                        type="checkbox"
                        className={`text-muted-foreground ${
                          isAllSelected ? "accent-gray-700 bg-grey-700 text-red-500" : ""
                        }`}
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                      />
                      Name{" "}
                      <CaretSortIcon
                        onClick={() => sortAccountList("ByAccountName")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>

                  <TableHead className="text-left">
                    <div className="flex items-center gap-2 justify-start">
                      Email{" "}
                      <CaretSortIcon
                        onClick={() => sortAccountList("ByAccountEmail")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
                  <TableHead className="text-left">
                    <div className="flex items-center gap-2 justify-start">
                      Type{" "}
                      <CaretSortIcon
                        onClick={() => sortAccountList("ByAccountType")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
                  <TableHead className="text-left">
                    <div className="flex items-center gap-2 justify-start">
                      Created at{" "}
                      <CaretSortIcon
                        onClick={() => sortAccountList("ByAccountCreatedAt")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
                  <TableHead className="text-left">
                    <div className="flex items-center gap-2 justify-start">
                      Updated at{" "}
                      <CaretSortIcon
                        onClick={() => sortAccountList("ByAccountUpdatedAt")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
        
                  <TableHead className="text-left"></TableHead>
                </TableRow>
              </TableHeader>

              
              <TableBody className="text-left text-[14px] font-normal text-[#020617] ">
                {currentAccounts.map((account) => {
                  let isSelected;
                  accountList.map((accounts) => {
                      isSelected = checkboxSelectedRows.includes(
                      accounts.accountId
                    );
                  })

                  return (
                    <TableRow
                      key={account.accountId}
                      className={`${isSelected ? "bg-gray-200" : ""}`}
                    >
                      <TableCell className="flex justify-start py-4 text-green-900">
                        <div className="flex items-center gap-6">
                            <input
                              type="checkbox"
                              className={`accent-gray-700 bg-grey-700 text-red-500 ${
                                          isAllSelected ? "accent-gray-700 bg-grey-700 text-red-500" : ""
                                          }`}
                              checked={checkboxSelectedRows.includes(account.accountId)}
                              onChange={() => handleCheckboxRowSelect(account.accountId)}
                            />
                          <span style={{ color: "#020617", fontSize: "14px" , fontWeight: "400"}}>
                            {account.name}
                          </span>
                        </div>
                      </TableCell>

                      <TableCell className="py-4">
                      <span style={{ color: "#020617", fontSize: "14px" , fontWeight: "400"}}>
                        {account.email}
                      </span>
                      </TableCell>

                      <TableCell className="py-4 flex items-center gap-2">
                      <span style={{ color: "#020617", fontSize: "14px" , fontWeight: "400"}}>
                      {account.type} 
                      </span>
                      </TableCell>
                  
                      <TableCell className="py-4">
                          <div className="flex items-center gap-2">
                          <span style={{ color: "#020617", fontSize: "14px" , fontWeight: "400"}}>
                            {new Date(account.createdAt).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })} ∙ {new Date(account.createdAt).toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell className="py-4">
                          <div className="flex items-center gap-2">
                          <span style={{ color: "#020617", fontSize: "14px" , fontWeight: "400"}}>
                            {new Date(account.updatedAt).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })} ∙ {new Date(account.updatedAt).toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                            </span>
                          </div>
                        </TableCell>


                      <TableCell className="flex justify-left py-4">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <DotsHorizontalIcon style={{ color: "#020617"}}
                              onClick={() => handleMenuToggle(account.accountId)}
                              className="cursor-pointer w-6 h-6"
                            />
                          </DropdownMenuTrigger>
                          {openMenuRowId === account.accountId && (
                            <DropdownMenuContent  className="w-48 h-auto">
                              {/* Conditionally render dropdown items based on account type */}
                              {account.type === "Personal" && (
                                <>
                                  <DropdownMenuItem onClick={() => handleView(account.accountId)}>
                                    View
                                  </DropdownMenuItem>
                                  <DropdownMenuItem onClick={() => handleImpersonate(account.accountId)}>
                                    Impersonate
                                  </DropdownMenuItem>
                                  <DropdownMenuItem onClick={() => handleDeleteClick(account.accountId)}>
                                    Delete Personal Account
                                  </DropdownMenuItem>
                                </>
                              )}
                              {account.type === "Advertiser" && (
                                <>
                                  <DropdownMenuItem onClick={() => handleView(account.accountId)}>
                                    View
                                  </DropdownMenuItem>
                                  <DropdownMenuItem onClick={() => handleDeleteClick(account.accountId)}>
                                    Delete Advertiser Account
                                  </DropdownMenuItem>
                                </>
                              )}
                              {account.type === "operator" && (
                                <>
                                  <DropdownMenuItem onClick={() => handleView(account.accountId)}>
                                    View
                                  </DropdownMenuItem>
                                  <DropdownMenuItem onClick={() => handleDeleteClick(account.accountId)}>
                                    Delete Telco Account
                                  </DropdownMenuItem>
                                </>
                              )}
                            </DropdownMenuContent>
                          )}
                        </DropdownMenu>
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>

        

          {/* Pagination controls */}
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center space-x-2 text-gray-500 text-sm ">
              <span>{`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(
                currentPage * rowsPerPage,
                accountList.length
              )} of ${accountList.length} row(s) selected`}</span>
            </div>
            <div className="flex items-center space-x-4 font-medium text-sm">
              <span>Rows per page:</span>
              <select
                className="ml-2 border border-gray-300 rounded px-2 py-1"
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setCurrentPage(1); // Reset to first page after changing rows per page
                }}
              >
                {[5, 10, 20].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                «
              </button>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                ‹
              </button>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                ›
              </button>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                »
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-[500px]">
          <h2 className="text-xl font-semibold mb-2">
            Here you will see all your accounts
          </h2>
        </div>
      )}
    </div>
  );
};

export default Accounts;
