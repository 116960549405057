import React, { FC, ReactNode, useEffect, useState } from "react";
import { Button } from "../../Components/ui/button";
import { Activity, Check, Users } from "lucide-react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../Components/ui/card";

import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import { ChartConfig, ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent,} from "../../Components/ui/chart";
import { CalendarIcon, PaperPlaneIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";
import { cn } from "../../lib/utils";
import { Calendar } from "../../Components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../../Components/ui/popover";
import axios from "axios";
import { Skeleton } from "../../Components/ui/skeleton";


export function DatePickerWithRange({
  className,
}: React.HTMLAttributes<HTMLDivElement>) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: new Date(), // Current date
    to: addDays(new Date(), 20), // Current date + 20 days
  });

  return (
    <div className={cn("flex justify-end gap-2 pb-4 ", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !date && "text-muted-foreground text-[#020617] border-red-500"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" style={{color:'#020617'}} />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

const fallbackData: ChartData[] = [
  { week: "Sunday", sms: 214, whatsapp: 140 },
  { week: "Monday", sms: 186, whatsapp: 80 },
  { week: "Tuesday", sms: 305, whatsapp: 200 },
  { week: "Wednesday", sms: 237, whatsapp: 120 },
  { week: "Thursday", sms: 73, whatsapp: 190 },
  { week: "Friday", sms: 209, whatsapp: 130 },
  { week: "Saturday", sms: 214, whatsapp: 140 },
];

interface ChartData {
  week: string;
  sms: number;
  whatsapp: number;
}

interface DashChartProps {
  data?: ChartData[]; 
}

const DashChart: FC<DashChartProps> = ({ data = fallbackData }) => {
  const chartConfig = {
    sms: {
      label: "sms",
      color: "hsl(var(--chart-4))",
    },
    whatsapp: {
      label: "whatsapp",
      color: "hsl(var(--chart-2))",
    },
  };

  return (
    <Card className="mt-[20px] w-[100%] h-fit relative">
      <CardHeader className="text-left">
        <CardTitle className="text-[#1C2024]">Ad spend</CardTitle>
        <CardDescription className="text-[#60646C] font-normal">Showing total ad spend for the last week</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig} className="w-full h-[200px]">
          <AreaChart
            accessibilityLayer
            data={data}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="week"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" />}
            />
            {/* Define the areas for each data type */}
            <Area
              dataKey="sms"
              type="natural"
              fill={chartConfig.sms.color}
              fillOpacity={0.4}
              stroke={chartConfig.sms.color}
              stackId="a"
            />
            <Area
              dataKey="whatsApp"
              type="natural"
              fill={chartConfig.whatsapp.color}
              fillOpacity={0.4}
              stroke={chartConfig.whatsapp.color}
              stackId="a"
            />
            <ChartLegend content={<ChartLegendContent />} />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};


const SecondDashChart: FC<DashChartProps> = ({ data = fallbackData }) => {
  const chartConfig = {
    sms: {
      label: "sms",
      color: "hsl(var(--chart-5))",  // Different color for the second chart
    },
    whatsapp: {
      label: "whatsapp",
      color: "hsl(var(--chart-3))", // Different color for the second chart
    },
  };

  return (
    <Card className="mt-[20px] w-[100%] h-fit relative">
      <CardHeader className="text-left">
        <CardTitle className="text-[#1C2024]">Send messages</CardTitle>
        <CardDescription className="text-[#60646C] font-normal">Showing total messages sent for the last week</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig} className="w-full h-[200px]">
          <AreaChart
            accessibilityLayer
            data={data}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="week"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" />}
            />
            {/* Define the areas for each data type */}
            <Area
              dataKey="sms"
              type="natural"
              fill={chartConfig.sms.color}
              fillOpacity={0.4}
              stroke={chartConfig.sms.color}
              stackId="a"
            />
            <Area
              dataKey="whatsApp"
              type="natural"
              fill={chartConfig.whatsapp.color}
              fillOpacity={0.4}
              stroke={chartConfig.whatsapp.color}
              stackId="a"
            />
            <ChartLegend content={<ChartLegendContent />} />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

interface CardProps {
  title: string;
  value: number | String;
  change: string;
  icon?: ReactNode;
}

const CardComponent: FC<CardProps> = ({ title, value, change, icon }) => {
  return (
    <Card className="w-full md:w-[200px] lg:w-[220px] xl:w-[240px] h-fit relative flex-grow border-[#E2E8F0]">
      <div className="p-1 pl-0 pr-0">
        {/* <DollarSign className='absolute top-7 right-6 text-gray-400'/> */}
        {icon && (
          <div className="absolute top-7 right-6 text-gray-400">{icon}</div>
        )}

        <CardHeader className="text-left pb-2">
          <CardTitle className="text-[14px] text-[#020617] font-medium leading-[20px] text-left">{title}</CardTitle>
        </CardHeader>

        <CardContent className="text-left text-[#020617] text-2xl font-bold leading-[24px] mt-1">
          {value}
          <div className="text-[12px] text-[#64748B] font-normal leading-[20px] mt-[2px]">
            {change}
          </div>
        </CardContent>
      </div>
    </Card>
  );
};

const SkeletonCard: FC = () => {
  return (
    <div className="flex-col">
      <div className="flex flex-wrap gap-2">
        <Card className="w-full md:w-[200px] lg:w-[220px] xl:w-[240px] h-fit relative">
          <Skeleton className="absolute top-5 right-2 text-gray-200" />
          <CardHeader className="text-left">
            <Skeleton className="h-4 w-[100px]" />
          </CardHeader>
          <CardContent className="text-left text-2xl font-bold">
            <Skeleton className="h-4 mt-2 w-[100px]" />
            <div className="text-sm text-gray-400 font-medium">
              <Skeleton className="h-4 w-[100px]" />
            </div>
          </CardContent>
        </Card>
      </div>
      <Skeleton />
    </div>
  );
};

const SkeletonChart: FC = () => {
  return (
    <Card className="mt-[20px] w-full md:w-[400px] lg:w-[500px] xl:w-[1000px] h-fit relative">
      <CardHeader className="text-left">
        <CardTitle>
          <Skeleton className="w-full h-" />
        </CardTitle>
        <CardDescription>
          <Skeleton className="w-[200px] h-4" />
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={{}} className="w-full h-[200px]">
          <Skeleton className="w-full h-full" />
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

const AdminHome: FC = () => {
  const [chartData, setChartData] = useState<any>();
  const [apiUrlAdminAcc, setapiUrlAdminAcc] = useState("");

  const [userCount, setUserCount] = useState<number | 0>(0);
  const [workspaceCount, setWorkspaceCount] = useState<number | 0>(0);
  const [campaignsCount, setCampaignsCount] = useState<number | 0>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);




  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const config = await response.json();
        setapiUrlAdminAcc(config.ApiUrlAdminAcc);
        console.log("apiUrlAdminAcc:" , apiUrlAdminAcc);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };

    fetchConfig();
  }, []);



    // Fetch user count
    const getUserCount = async () => {
      setIsLoading(true);
      try {
        console.log("Fetching User Count...");
        const response = await axios.get(`${apiUrlAdminAcc}/GetUserCount`);
        console.log("User Count Response:", response.data);
        if (response.data && response.data.totalUserCount !== undefined) {
          setUserCount(response.data.totalUserCount);
        } else {
          console.log("No user count available in response.");
        }
      } catch (error) {
        console.error("Error fetching user count:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    // Fetch workspace count
    const getWorkspaceCount = async () => {
      setIsLoading(true);
      try {
        console.log("Fetching Workspace Count...");
        const response = await axios.get(`${apiUrlAdminAcc}/GetWorkspaceCount`);
        console.log("Workspace Count Response:", response.data);
        if (response.data && response.data.totalWorkspaceCount !== undefined) {
          setWorkspaceCount(response.data.totalWorkspaceCount);
        } else {
          console.log("No workspace count available in response.");
        }
      } catch (error) {
        console.error("Error fetching workspace count:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    // Fetch campaigns count
    const getCampaignsCount = async () => {
      setIsLoading(true);
      try {
        console.log("Fetching Campaigns Count...");
        const response = await axios.get(`${apiUrlAdminAcc}/GetCampaignsCount`);
        console.log("Campaigns Count Response:", response.data);
        if (response.data && response.data.totalCampaignsCount !== undefined) {
          setCampaignsCount(response.data.totalCampaignsCount);
        } else {
          console.log("No campaigns count available in response.");
        }
      } catch (error) {
        console.error("Error fetching campaigns count:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    // Call all APIs on component mount
    useEffect(() => {
      if (apiUrlAdminAcc) {
        fetchData();
        getUserCount();
        getWorkspaceCount();
        getCampaignsCount();      
      }
    }, [apiUrlAdminAcc]);
  


  const fetchData = async () => {
    const data = fallbackData; // Use static data or fetched data here
    setChartData(data);
  };
  
  return chartData ? (
    <div className="flex-col w-full">
      <div className="flex mt-[-15px] justify-end">
        <div>
          <DatePickerWithRange />
        </div>
        <div>
          <Button className="ml-2 w-fit font-normal text-[#020617]" variant={"outline"}>
            By Week
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 w-full justify-between border-orange-600">
        <CardComponent title="Users" value={userCount} change="+20.1 from last week" />
        <CardComponent title="Workspaces" value={workspaceCount} change="+180.1% from last week" />
        <CardComponent title="Campaigns" value={campaignsCount} change="+19% from last week" />
        <CardComponent title="Ad Spend" value="AED 280,000" change="+201% from last week" />
      </div>
      <DashChart data={chartData?.chartDetails} />
      <SecondDashChart data={chartData?.chartDetails} />
    </div>
  ) : (
    <div className="flex-col w-full">
      <div className="flex mt-[-15px] justify-end">
        <div>
          <DatePickerWithRange />
        </div>
        <div>
          <Button className="ml-2 w-fit font-normal" variant={"outline"}>
            By Week
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 w-full justify-between">
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </div>
      <SkeletonChart />
    </div>
  );
};

export default AdminHome;
