import React, { useState, ChangeEvent, useEffect } from 'react';
import { Typography, Card } from '@mui/material';
import { Input } from 'src/Components/ui/input';
import { Button } from 'src/Components/ui/button';
//import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";
import { useNavigate,useParams,useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { RootState } from 'src/State/store';
import { Image } from 'lucide-react';
import { toast, useToast } from "../../Components/ui/use-toast";
import { Toaster } from "../../Components/ui/toaster";
import crypto from 'crypto-js';

const Profile: React.FC = () => {
  const [name, setName] = useState('Sebastian Swaczynski');
  const [email, setEmail] = useState('');
  const [repeatEmail, setRepeatEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');
  const [fullName, setFullName] = useState('');
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [fullNameError, setFullNameError] = useState <string | null> (null);
  const toast=useToast();

  const navigate = useNavigate();
  const id=localStorage.getItem("userid");
  const mailId = useSelector((state:RootState)=>state.authentication.userEmail);

  const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState("");
  const [API_URL , setAPI_URL] = useState("");

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        
        const response = await fetch("/config.json");
        const config = await response.json();
        setApiUrlAdvAcc(config.ApiUrlAdvAcc); // Set API URL from config
        console.log(apiUrlAdvAcc);

        setAPI_URL(config.API_URL);
        console.log(API_URL);
       
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };

    fetchConfig();
  }, []);


  useEffect(() => {
    if (apiUrlAdvAcc) {
      const id=localStorage.getItem("userid");
      GetPersonalinfoByEmail();
    }
  }, [apiUrlAdvAcc]); // Runs when apiUrlAdminAcc is updated


  

  function hashPasword(pass: string): string {
    return crypto.SHA256(pass).toString();
  }

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setProfilePicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string); 
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAccountDeletion = () => {
    toast.toast({
      title:"Error",
      description:"Your account has been deleted"
    })
  
  };


  const validateName = (value: string): boolean => {
    const regex = /^[a-zA-Z][a-zA-Z0-9\s_-]*$/;
    return regex.test(value);
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const validatePassword = (pass: string): void => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(pass)) {
      setPasswordError(
        "Password must be at least 8 characters long, with at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
    } else {
      setPasswordError(null);
    }
  };


  const reValidatePassword = (pass: string): void => {
    if (pass !== newPassword) {
      setPasswordError("Passwords do not match.");
    } else {
      setPasswordError(null);
    }
  };

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const nameRegex = /^[a-zA-Z].*$/;

    if (!value) {
      setFullNameError('Please fill the field.'); // Empty field error
    } else if (!nameRegex.test(value)) {
      setFullNameError('Name must start with a letter.'); // Validation error
    } else {
      setFullNameError(''); // Clear error for valid input
    }

    setFullName(value); // Update the state
  };

 const GetPersonalinfoByEmail = () => {
       axios.post(`${apiUrlAdvAcc}/GetPersonalinfoByEmail`, {
       "userEmail": mailId, 
})
  
  .then(response => {
    console.log("response:" , response);
      if (response.data[0]?.Status === 'Success') {
                  const userFirstName = response.data[0].First_Name;  
                  const userLastName = response.data[0].Last_Name; 
                  const emailAcc = response.data[0].email;
                  const rawPassword = response.data[0].password;
                  
                  setFirstName(userFirstName);
                  setLastName(userLastName);
                  setFullName(userFirstName+userLastName)
                  setEmail(emailAcc);
                  setNewPassword(rawPassword);
                  
               
                  console.log('UemailAcc:', emailAcc);
                  console.log('UuserFirstName:', userFirstName);
                  console.log('UuserLastName:', userLastName);
                  console.log("RawPassword:" ,rawPassword );
          // toast.toast({
          //     title:"Success",
          //     description:"Profile updated successfully"
          // });
      } else {
          console.error('Failed to get user details:', response.data.status_Description);
      }
  })
  .catch(error => {
      console.error('Error in getting Oersonal info data:', error);
  });
};
  const handleProfileUpdate = async () => {
  console.log("hello");
          // Validation: Check if the full name is empty
          if (!fullName.trim()) {
            setFullNameError('Please fill the field.'); // Prevent submission if the input is empty
            return;
          }

          // If there's a validation error, prevent submission
          if (fullNameError) {
            return;
          }

          const nameParts = fullName.split(" ");

          const Fname = nameParts[0] || ""; 
          const Lname=nameParts.slice(1).join(" ") || ""; 


      axios.put(`${apiUrlAdvAcc}/UpdateUserProfile/updateProfile`, {
        "UserEmail": mailId, // Email ID for the user
        "firstName": Fname ,// Stringify the current notification state
        "lastName" : Lname
    })
    
    .then(response => {
      console.log("response:" , response);
      if (response.data.status === 'Success') {

          setFullName(`${Fname} ${Lname}`);
          console.log(`${Fname} ${Lname}`)
          toast.toast({
            title:"Success",
            description:"Profile Updated Successfully"
          })

          setTimeout(() => {
          
          }, 3000);
      } else {
          toast.toast({
            title:"Error",
            description:response.data.Status_Description
          })
          console.error('Update failed:', response.data.Status_Description);
        }
      })
          .catch(error => {
      toast.toast({
        title:"Error",
        description:"Error in Profile update"
      })
      console.error("Error in Profile update", error);
    })
    
  };

  const handleUpdatePassword = async () => {
 
            if (!newPassword || !repeatNewPassword) {
            toast.toast({
              title: "Error",
              description: "Both password fields are required",
            })
            return;
          }
   
          if (newPassword !== repeatNewPassword) {
            toast.toast({
              title: "Error",
              description: "Passwords do not match",
            })
            return;
          }
          
        axios.post(`${API_URL}/UpdatePassword`, {
          "email": mailId, // Email ID for the user
          "newPassword": hashPasword(newPassword)// Stringify the current notification state
      })
      .then(response => {
            if (response.data[0]?.Status === "Success") {   
                    toast.toast({
                     title: "Success",
                     description: "Password updated successfully",
                    })
                  } else {
                    toast.toast({
                        title:"Error",
                        description:"Failed to update Password"
                    })
    
                    console.error(response.data.status_Description);
                }
            }) 
             .catch(error => {
           console.error("Error during UpdatePassword API call:", error);
           toast.toast({
             title: "Error",
             description: "An error occurred, please try again",
           })
          });
        };
    

  useEffect(() => {
   const id=localStorage.getItem("userid");
   console.log("id :"+ id);
    console.log(" Redux mail id: "+mailId);
    console.log("fullNameE" + fullName );
  }, []); 

    
        const handleEmailUpdate = async () => {
          // Check if email and repeatEmail match before proceeding
          if (email !== repeatEmail) {
            setEmailError('Emails do not match!');
            return; // Stop further execution if emails don't match
          }
            // Validate email
          if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");

            setIsLoading(false);
            return;
          }
        setEmailError('');

      axios.put(`${apiUrlAdvAcc}/UpdateUserEmailAddress/updateEmailAddress`, {
        "existingEmail": mailId, // Email ID for the user
        "newEmail": email// Stringify the current notification state
    })
    .then(response => {
      console.log("response:" , response);
      if (response.data.status === 'Success') {
        setEmail(email);
        toast.toast({
          title:"Success",
          description:"Your Email Address Updated successfully"
        })

      setTimeout(() => {
      
      }, 3000);
      } else {
        toast.toast({
          title: "Error",
          description: "Failed to update email address"
        })
      console.error('Update failed:', response.data.Status_Description);
      }
    })
    .catch(error => {
      
        toast.toast({
          title: "Error",
          description: "Failed to update email address"
        })
      console.error("Error in Email update", error);
    });
  };


  const handlePasswordUpdate = () => {
    setPasswordError('');
    if (newPassword === repeatNewPassword) {
      toast.toast({
        title: "Success",
        description: "Password updated"
      })

    } else {
      setPasswordError('New passwords do not match!');
    }
  };

  return (
    
    <div className="flex h-screen"> 
    <div className="flex-grow p-2 overflow-y-auto no-scrollbar">


       {/* Profile Picture */}
      <Card className="mb-8 p-6 border border-grey text-left max-w-xl ">
        <Typography component="h2" className="mb-4" style={{fontSize: '14px' , fontWeight: 700 , color: '#020617', paddingBottom: '6px'}} ><b>Your profile picture</b></Typography>
        <Typography className="mb-4 mt-1" style={{fontSize:'14px', color:'#64748B' , fontWeight: 400}}>Please choose a photo to upload as your profile picture.</Typography>
        {/* <input 
          type="file" 
          accept="image/*" 
          onChange={handleImageUpload} 
          className="mb-4"
        />
        {imagePreview && (
          <div className="mb-4 w-32 border border-black overflow-hidden rounded"> 
            <img 
              src={imagePreview} 
              alt="Profile Preview" 
              className="w-full h-auto max-h-32 object-contain" 
            />
          </div>
        )} */}
        <div className="flex items-center gap-2 mt-4 cursor-pointer" 
          onClick={() => document.getElementById('file-upload')?.click()}>
          <div>
            {(!imagePreview && <Image className='h-[24px] w-[24px]'/>)
            || (imagePreview && <img src={imagePreview} className='h-[24px]'/>)}     
          </div>
          <div className='flex-col'>
            <Typography style={{fontSize:'12px' , fontWeight: 600 , lineHeight: '14.52px' , paddingBottom: '2px'}}>
              Upload your profile picture
            </Typography>
            <Typography style={{fontSize:'10px',fontWeight: 100 , lineHeight: '12.1px'}}>
             Choose a photo to upload as your profile picture.
            </Typography>
          </div>
        </div>
        <input 
          id="file-upload"
          type="file" 
          accept="image/*" 
          onChange={handleImageUpload} 
          className="hidden"
        />
      </Card>

      <Toaster />
      <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
        <Typography component="h3" 
         className="mb-4" style={{fontSize: '14px' , fontWeight: 700 , color: '#020617', paddingBottom: '6px'}} ><b>Your name</b></Typography>
        <p className='' style={{fontSize:'14px', color:'#64748B' , fontWeight: 400}}>Update your name to be displayed on your profile.</p>

      
              <Input
                className='mb-4 mt-4'
                required
                id="profilename"
                placeholder="Sebastian Swaczynski"
                value={fullName}
                onChange={handleFirstNameChange}
              />
        {fullNameError && (
                <p className="text-red-500 text-xs mt-1 mb-2">{fullNameError}</p>
              )}

        <Button onClick={handleProfileUpdate} disabled={isLoading} className="py-1 px-3 text-sm w-[128px] mt-[-2]" style= {{ fontWeight: 400 , fontSize: '14px'}}>
          {isLoading ? 'Updating...' : 'Update profile'}
        </Button>
      </Card>


      {/* Update Email Section */}
      <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
        <Typography component="h3" className="mb-4" style={{fontSize:'14px',fontWeight: 700,color: '#020617' , paddingBottom: '6px'}} ><b>Update your email</b></Typography>
        <Typography component="p" className="mb-4" style={{fontSize:'14px', color:'#64748B' , fontWeight: 400}}>Update your email address you use to login to your account.</Typography>
        <Input className="mb-2 mt-4" required type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your new email" aria-label="Your new email" />
        <Input className="mb-2 mt-2" value={repeatEmail} onChange={(e) => setRepeatEmail(e.target.value)} placeholder="Repeat email" aria-label="Repeat email" />
        {emailError && <Typography className="text-red-500 text-xxs mb-4 mt-2">{emailError}</Typography>}
        <Button onClick={handleEmailUpdate} className="py-2 px-4 w-[178px] mt-[-1]" style= {{ fontWeight: 400 , fontSize: '14px'}}>Update email address</Button>
      </Card>
  

      {/* Update Password Section */}
      <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
        <Typography component="h3" className="mb-4" style={{fontSize: '14px' , fontWeight: 700 , color: '#020617', paddingBottom: '6px'}} ><b>Update your password</b></Typography>
        <Typography component="p" className="mb-4" style={{fontSize:'14px', color:'#64748B' , fontWeight: 400}}>Update your password to keep your account secure.</Typography>
        <Input className="mb-2 mt-4" type="password" value={newPassword} onChange={(e) => {setNewPassword(e.target.value); validatePassword(e.target.value); }} placeholder="New password" aria-label="New password" />
        <Input className="mb-2 mt-2" type="password" value={repeatNewPassword} onChange={(e) => {setRepeatNewPassword(e.target.value); reValidatePassword(e.target.value);}} placeholder="Repeat new password" aria-label="Repeat new password" />
        {passwordError && <Typography className="text-red-500 mb-4 mt-1 text-xs ">{passwordError}</Typography>}
        <Button onClick={handleUpdatePassword} className="py-2 px-4 text-sm w-[150px] mt-2" style= {{ fontWeight: 400 , fontSize: '14px'}}>Update password</Button>
      </Card>
  
       {/* Delete Account Section */}
          <Card className="mb-8 p-6 border border-grey text-left max-w-xl border-2 border-red-600">
          <Typography component="h3" className="mb-4"
                        style={{ 
                        fontWeight: 600, 
                        fontSize: '14px', 
                        lineHeight: '24px', 
                        color: '#020617', 
                        letterSpacing: '-1.5%' ,
                        paddingBottom: '6px'
            
            }}> Danger zone</Typography>
            <Typography component="p" className= "mb-4" style={{fontWeight: 400 , color:'#64748B' ,fontSize:'14px' , lineHeight: '20px' ,paddingBottom: '6px'}}>
            Some actions cannot be undo. Please be careful.
            </Typography>
            <Typography component="h3" className="mb-4"
             style={{ 
              fontWeight: 600, 
              fontSize: '14px', 
              lineHeight: '24px', 
              color: '#020617', 
              paddingBottom: '6px',
              letterSpacing: '-1.5%' 
  
            }}>Delete team</Typography>
            <Typography component="p" className="mb-4" style={{fontWeight: 400 , fontSize:'14px' , color:'#64748B' ,lineHeight: '20px'}}>
            This will delete your account and the accounts you own. Furthermore, we will immediately cancel any active subscriptions. This action cannot be undone.
            </Typography>
            <Button 
              onClick={handleAccountDeletion} 
              className="text-white py-2 px-4 text-sm hover:bg-red-700 w-[167px]" style={{ backgroundColor: "#EF4444", fontWeight: 500, fontSize: '14px' }}>
              Delete your account
            </Button>
          </Card>
      <div className="mb-20" />
    </div>
  </div>
);
};

export default Profile;