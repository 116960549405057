import React, { useState, useEffect } from 'react';
import { Typography, Grid, Switch } from '@mui/material';
import { Button } from 'src/Components/ui/button';
import { Card } from 'src/Components/ui/card';
import axios from 'axios';
import config from '../../config.json';
//import { ToastContainer, toast } from "react-toastify";
import { toast, useToast } from "../../Components/ui/use-toast";
import { Toaster } from "../../Components/ui/toaster";

// Define the types for each state
type CampaignsState = {
    Campaign_status: number;
    Account_activity: number;
};

type SecurityAndBillingState = {
    Account_activity: number;
};

type NotificationsState = {
    Email: {
        Campaigns: CampaignsState;
        Security: SecurityAndBillingState;
        Billing: SecurityAndBillingState;
    };
    App: {
        Campaigns: CampaignsState;
        Security: SecurityAndBillingState;
        Billing: SecurityAndBillingState;
    };
};

interface NotificationDataItem {
    notification_data: string;
}

const Notification: React.FC<{email:string}> = ({email}) => {
    const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState("");
    const toast=useToast();
    const [notifications, setNotifications] = useState<NotificationsState>({
        Email: {
            Campaigns: { Campaign_status: 0, Account_activity: 0 },
            Security: { Account_activity: 0 },
            Billing: { Account_activity: 0 },
        },
        App: {
            Campaigns: { Campaign_status: 0, Account_activity: 0 },
            Security: { Account_activity: 0 },
            Billing: { Account_activity: 0 },
        },
    });
    

    // Function to map API data to state
    const mapApiDataToState = (apiData: NotificationsState) => {
        setNotifications(apiData);
    };  
    
    useEffect(() => {
        const fetchConfig = async () => {
          try {
            
            const response = await fetch("/config.json");
            console.log(response);
           
            const config = await response.json();
          
            console.log("Config loaded:", config); // Debugging log
            setApiUrlAdvAcc(config.ApiUrlAdvAcc); // Set API URL from config
            console.log(apiUrlAdvAcc);
           
          } catch (error) {
            console.error("Error loading config:", error);
          }
        };
    
        fetchConfig();
      }, []);



    // Fetch notification data from API
    useEffect(() => {
        console.log(email);
        
        axios
            .get(`${apiUrlAdvAcc}/GetNotificationSettings?EmailId=`+email)
            .then(response => {
                if (response.data.status === 'Success') {
                    // Parse the notification_data string into JSON
                    const firstItem = response.data.notificationData[0];
                    const parsedData: NotificationsState = JSON.parse(firstItem.notification_data);
                    // Map the received API data to the component's state
                    mapApiDataToState(parsedData);
                    console.log(parsedData); // Log the parsed notification data
                } else {
                    console.error(response.data.status_Description);
                }
            
            })
            .catch(error => {
                console.error('Error fetching notification settings:', error);
            });
    }, [apiUrlAdvAcc]);



    // Toggle functions for Campaigns and Security/Billing
    const handleToggleCampaigns = (section: keyof NotificationsState, field: keyof CampaignsState) => {
        setNotifications(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                Campaigns: {
                    ...prevState[section].Campaigns,
                    [field]: prevState[section].Campaigns[field] === 1 ? 0 : 1,
                },
            },
        }));
    };

    const handleToggleSecurityBilling = (
        section: keyof NotificationsState,
        subSection: 'Security' | 'Billing',
        field: keyof SecurityAndBillingState
    ) => {
        setNotifications(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [subSection]: {
                    ...prevState[section][subSection],
                    [field]: prevState[section][subSection][field] === 1 ? 0 : 1,
                },
            },
        }));
    };

    // Function to send updated notification data to the API
    const updateNotificationData = () => {
        axios.put(`${apiUrlAdvAcc}/UpdateNotificationSettings/UpdateNotificationSettings`, {
            "emailId": email, // Email ID for the user
            "notificationData": JSON.stringify(notifications) // Stringify the current notification state
        })
        .then(response => {
            if (response.data.status === 'Success') {
                console.log('Notification data updated successfully');
                toast.toast({
                    title:"Success",
                    description:"Notifications updated successfully"
                })
;
            } else {
                toast.toast({
                    title:"Error",
                    description:"Failed to update notifications"
                })

                console.error(response.data.status_Description);
            }
        })
        .catch(error => {
            console.error('Error updating notification data:', error);
        });
    };

    return (
        <div className="flex">
            <Toaster/>
            <div className="flex-grow ml-0 p-2 h-screen overflow-y-auto">
                {/* Campaigns Section */}
                <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Typography className="text-[14px]">
                                <b>Campaigns</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center',color:'#64748B' }}>
                            <Typography style={{ fontSize:'12px',}}>
                                <p>Email</p>
                            </Typography >
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center',color:'#64748B' }}>
                            <Typography style={{ fontSize:'12px',}}>
                                <p>App</p>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center" >
                        <Grid item xs={8}>
                            <Typography style={{ fontSize:'14px',color:'#64748B' }}>Campaign status changed</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Switch
                                checked={notifications.Email.Campaigns.Campaign_status === 1}
                                onChange={() => handleToggleCampaigns('Email', 'Campaign_status')}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Switch
                                checked={notifications.App.Campaigns.Campaign_status === 1}
                                onChange={() => handleToggleCampaigns('App', 'Campaign_status')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Typography style={{ fontSize:'14px',color:'#64748B' }}>Receive emails about account activity</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Switch
                                checked={notifications.Email.Campaigns.Account_activity === 1}
                                onChange={() => handleToggleCampaigns('Email', 'Account_activity')}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Switch
                                checked={notifications.App.Campaigns.Account_activity === 1}
                                onChange={() => handleToggleCampaigns('App', 'Account_activity')}
                            />
                        </Grid>
                    </Grid>
                </Card>

                {/* Security Section */}
                <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Typography className='text-[14px]'>
                                <b>Security</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center',color:'#64748B' }}>
                            <Typography style={{ fontSize:'12px',}}>
                                <p>Email</p>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center',color:'#64748B' }}>
                            <Typography style={{ fontSize:'12px',}}>
                                <p>App</p>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Typography style={{ fontSize:'14px',color:'#64748B' }}>Receive emails about account activity</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Switch
                                checked={notifications.Email.Security.Account_activity === 1}
                                onChange={() => handleToggleSecurityBilling('Email', 'Security', 'Account_activity')}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Switch
                                checked={notifications.App.Security.Account_activity === 1}
                                onChange={() => handleToggleSecurityBilling('App', 'Security', 'Account_activity')}
                            />
                        </Grid>
                    </Grid>
                </Card>

                {/* Billing Section */}
                <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Typography className='text-[14px]'>
                                <b>Billing</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center',color:'#64748B' }}>
                            <Typography style={{ fontSize:'12px',}}>
                                <p>Email</p>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center',color:'#64748B' }}>
                            <Typography style={{ fontSize:'12px',}}>
                                <p>App</p>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Typography style={{ fontSize:'14px',color:'#64748B' }}>Receive emails about account activity</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Switch
                                checked={notifications.Email.Billing.Account_activity === 1}
                                onChange={() => handleToggleSecurityBilling('Email', 'Billing', 'Account_activity')}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Switch
                                checked={notifications.App.Billing.Account_activity === 1}
                                onChange={() => handleToggleSecurityBilling('App', 'Billing', 'Account_activity')}
                            />
                        </Grid>
                    </Grid>
                </Card>
                <div className='w-full flex justify-start'>
                    <Button color="primary" className='w-[168px] m-0 text-[14px]' onClick={()=>updateNotificationData()}>
                        Update notifications
                    </Button>
                </div>

            </div>
        </div>
    );
};

export default Notification;
