import React, { useState, useEffect } from "react";
import { Button } from "../../Components/ui/button";
import { Input } from "../../Components/ui/input";
import { Badge } from "../../Components/ui/badge";
import {
  DotsHorizontalIcon,
  CaretSortIcon,
  FileIcon
} from "@radix-ui/react-icons";

import { FiFilter } from "react-icons/fi";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/ui/table";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../Components/ui/dropdown-menu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  PlayIcon,
  PauseIcon,
  StopwatchIcon,
  MagnifyingGlassIcon,
  CheckIcon,
} from "@radix-ui/react-icons";
import { Skeleton } from "../../Components/ui/skeleton";
import  DropdownMenuDemo  from "../../Components/Filter/AudienceDropdown"
import { useDispatch } from "react-redux";
import { setCreateBreadCrumb } from "../../State/slices/AdvertiserAccountSlice";

interface Audience {
  telco_id: number;
  telco_name: string;
  status: string;
  updatedAt: string;
  recipients : number;
}

type AudienceCheck = {
  telco_id: number;
  telco_name: string;
};



const Audiences: React.FC = () => {
  const [openMenuRowId, setOpenMenuRowId] = useState<number | null>(null);

  const [audienceList, setaudienceList] = useState<Audience[]>([]);
  const [currentAudiences, setCurrentAudiences] = useState<Audience[]>([]);


  const navigate = useNavigate();


  const [isSorted, setIsSorted] = useState(false);
  const [originalAudiences, setOriginalAudiences] = useState(currentAudiences);

  const [checkboxSelectedRows, setCheckboxSelectedRows] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5); // Default 5 rows per page
  const [searchTerm, setSearchTerm] = useState("");
  const [apiUrlAdminAcc, setapiUrlAdminAcc] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const [filterData, setFilterData] = useState({
    filter: "All Audiences",
    value: "",
  });

  
  const [hasAudiences, setHasAudiences] = useState(false);
  const dispatch = useDispatch();

  

  const handleCheckboxRowSelect = (id: number) => {
    setCheckboxSelectedRows((prev) => {
      const newSelectedRows = prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id];
      setIsAllSelected(newSelectedRows.length === currentAudiences.length); // Update `isAllSelected` if all rows are selected
      return newSelectedRows;
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setCheckboxSelectedRows([]);
    } else {
      const allIds = currentAudiences.map((audience) => audience.telco_id);
      setCheckboxSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const config = await response.json();
        setapiUrlAdminAcc(config.ApiUrlAdminAcc);
        console.log("apiUrlAdminAcc:" , apiUrlAdminAcc);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (apiUrlAdminAcc) {
      getaudienceList();
    }
  }, [apiUrlAdminAcc]); // Runs when apiUrlAdminAcc is updated



  const filteredAudiences = audienceList.filter((audience) => {
    const matchesSearchTerm = searchTerm
      ? audience.telco_name.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
  
    const matchesMainFilter =
      filterData.filter === "Status"
        ? filterData.value === "All" || audience.status === filterData.value
        : filterData.filter === "UpdatedAt"
        ? true // No specific filtering for UpdatedAt (placeholder logic)
        : true;
  
    return matchesSearchTerm && matchesMainFilter;
  });
  
  
  

  // Calculate total pages for filtered Audiences
  const totalPages: number = Math.ceil(filteredAudiences.length / rowsPerPage);

  useEffect(() => {
    const newCurrentAudiences = filteredAudiences.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );

    setCurrentAudiences(newCurrentAudiences);
  }, [filterData, audienceList, currentPage, rowsPerPage, searchTerm]);
  // Only re-run if dependencies change

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

 

  // Function to handle the actual delete after confirmation

  useEffect(() => {
    console.log(
      "filter data: " +
        filterData.filter +
        " " +
        filterData.value
    );
  }, [filterData]);



  const getaudienceList = async () => {
    setIsLoading(true);
    try {
      console.log( " Entered" );
      const response = await axios.get(`${apiUrlAdminAcc}/GetAudienceList`);
      console.log( "Response : " , response.data.audienceList);
      if (response.data && response.data.audienceList) {
        setaudienceList(response.data.audienceList);
        setIsLoading(false);
        console.log("audience List : ", response.data.audienceList);
      } else {
        console.log("No audience list available in response.");
        setIsLoading(false);
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching audience list:", error);
    } finally {
      // Ensure the menu is closed after fetching data
    }
  };

 

  const handleMenuToggle = (rowId: number) => {
    setOpenMenuRowId(openMenuRowId === rowId ? null : rowId);
  };

  const sortaudienceList = (tableHeader: string) => {
    const sortByField = (
      field: keyof (typeof currentAudiences)[0],
      type: "string" | "number" | "date" = "string"
    ) => {
      const sortedAudiences = [...currentAudiences].sort((a, b) => {
        if (type === "number") {
          return Number(a[field]) - Number(b[field]);
        } else if (type === "date") {
          return (
            Date.parse(a[field] as string) - Date.parse(b[field] as string)
          );
        } else {
          return String(a[field]).localeCompare(String(b[field]));
        }
      });
      setOriginalAudiences(currentAudiences);
      setCurrentAudiences(sortedAudiences);
    };

    if (isSorted) {
      setCurrentAudiences(originalAudiences);
    } else {
      switch (tableHeader) {
        case "ByAudienceName":
          sortByField("telco_name", "string");
          break;
        case "ByAudienceStatus":
          sortByField("status", "string");
          break;
        case "ByAudienceUpdatedAt":
          sortByField("updatedAt", "date"); // Sorting by start date
          break;
        case "ByAudiencerecipients":
          sortByField("recipients", "number");
          break;

        default:
          console.warn("Unknown table header");
      }
    }

    setIsSorted(!isSorted);
    console.log("Sorted Audiences:", currentAudiences); // For debugging
  };

  const renderStatusIcon = (status: any) => {
    switch (status) {
      case "Syncing...":
        return <StopwatchIcon className="text-gray-500" />; // Stopwatch icon for 'Pending'
      case "Updated":
        return (
          <CheckIcon className="text-gray-500 rounded-full border border-gray-500" />
        ); // Check icon for 'Completed'
      default:
        return null; // If no match, return nothing
    }
  };


  useEffect(() => {
    setHasAudiences(audienceList.length > 0);
  }, [audienceList]);
  // const hasAudiences = audienceList.length > 0;

  const handleView = (audienceId: number) => {
    console.log(`View audience with ID: ${audienceId}`);
  };
  

  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div>
          <div className="flex  mt-4">
            <Skeleton className="w-[350px] h-10 mb-4" />{" "}
            {/* Skeleton for search input */}
            <div className="flex items-end ml-auto">
              {/* <Skeleton className="w-32 h-10 mb-4 ml-4" />{" "} */}
              {/* Skeleton for date picker */}
              <Skeleton className="w-32 h-10 mb-4 ml-4" />{" "}
              {/* Skeleton for filter button */}
              {/* <Skeleton className="w-32 h-10 mb-4 ml-4" />{" "} */}
              {/* Skeleton for export button */}
            </div>
          </div>

          {/* Table Skeleton */}
          <div className="rounded-md border">
            <Table className="rounded-xl border-gray-200">
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <Skeleton className="w-full h-6" />{" "}
                    {/* Skeleton for table header */}
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Array.from({ length: 5 }).map((_, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>{" "}
                    {/* Skeleton for table cell */}
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
      <div className="fixed flex justify-end items-end right-0 top-[-15px] z-20 p-4">
        
      </div>

      {hasAudiences ? (
        <div>
          {/* Existing table code here */}
          <div className="flex  mt-2">
            <div className=" ">
              <Input
                placeholder="Search audience by name..."
                className="w-[350px] text-[14px] font-normal text-[#64748B]"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex items-end ml-auto ">
             <DropdownMenuDemo setFilterData={setFilterData} />
             <Button variant="outline" className="w-24 mb-6 ml-4 mt-[-6] text-[#020617]">
                <FileIcon className="mr-2 text-[#020617]" /> Export
              </Button>
            </div>

            
          </div>

          <div className="rounded-md border">
            <Table
              className="rounded-xl whitespace-nowrap border-gray-200  "
              style={{ color: "#020202", fontSize: "15px" }}
            >
              <TableHeader className="text-center text-[14px] font-medium">
                <TableRow>
                  <TableHead className="">
                    <div className="flex items-center gap-6 justify-start cursor-pointer">
                      <input
                        type="checkbox"
                        className={`text-muted-foreground ${
                          isAllSelected ? "accent-gray-700 bg-grey-700 text-red-500" : ""
                        }`}
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                      />
                      Telco{" "}
                      <CaretSortIcon
                        onClick={() => sortaudienceList("ByAudienceName")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>

                  <TableHead className="text-left">
                    <div className="flex items-center gap-2 justify-start">
                      Status{" "}
                      <CaretSortIcon
                        onClick={() => sortaudienceList("ByAudienceStatus")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
                  <TableHead>               
                    <div className="flex items-center gap-2 justify-start">
                      Updated at{" "}
                      <CaretSortIcon
                        onClick={() => sortaudienceList("ByAudienceUpdatedAt")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
                  <TableHead>             
                    <div className="flex items-center gap-2 justify-start">
                      recipients{" "}
                      <CaretSortIcon
                        onClick={() => sortaudienceList("ByAudiencerecipients")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>

        
                  <TableHead className="text-left"></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="text-left text-[14px] font-normal text-[#020617] ">
                {currentAudiences.map((audience) => {
                  let isSelected;
                  audienceList.map((Audiences) => {
                      isSelected = checkboxSelectedRows.includes(
                      Audiences.telco_id
                    );
                  })

                  return (
                    <TableRow
                      key={audience.telco_id}
                      className={`${isSelected ? "bg-gray-200" : ""}`}
                    >
                      <TableCell className="flex justify-start py-4 text-green-900">
                        <div className="flex items-center gap-6">
                            <input
                              type="checkbox"
                              className={`accent-gray-700 bg-grey-700 text-red-500 ${
                                          isAllSelected ? "accent-gray-700 bg-grey-700 text-red-500" : ""
                                          }`}
                              checked={checkboxSelectedRows.includes(audience.telco_id)}
                              onChange={() => handleCheckboxRowSelect(audience.telco_id)}
                            />
                          <span style={{ color: "#020617", fontSize: "14px" , fontWeight: "400"}}>
                            {audience.telco_name}
                            
                          </span>
                        </div>
                      </TableCell>


                      <TableCell className="py-4">
                        <div className="flex items-center gap-2">
                          {/* Render the icon */}
                          <span className="flex-shrink-0">
                            {renderStatusIcon(audience.status)}
                          </span>
                          {/* Render the status text */}
                          <span
                            style={{
                              color: "#020617",
                              fontSize: "14px",
                              fontWeight: "400",
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                          >
                            {audience.status}
                          </span>
                        </div>
                      </TableCell>


                        <TableCell className="py-4">
                          <div className="flex items-center gap-2">
                          <span style={{ color: "#020617", fontSize: "14px" , fontWeight: "400"}}>
                            {new Date(audience.updatedAt).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })} ∙ {new Date(audience.updatedAt).toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell className="py-4">
                      <span style={{ color: "#020617", fontSize: "14px" , fontWeight: "400"}}>
                        {audience.recipients}
                      </span>
                      </TableCell>


                      <TableCell className="flex justify-left py-4">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <DotsHorizontalIcon style={{ color: "#020617"}}
                              onClick={() => handleMenuToggle(audience.telco_id)}
                              className="cursor-pointer w-6 h-6"
                            />
                          </DropdownMenuTrigger>
                          {openMenuRowId === audience.telco_id && (
                            <DropdownMenuContent  className="w-48 h-auto">
                            
                                  <DropdownMenuItem onClick={() => handleView(audience.telco_id)}>
                                    Sync
                                  </DropdownMenuItem>
                                
                            </DropdownMenuContent>
                          )}
                        </DropdownMenu>
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>

        

          {/* Pagination controls */}
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center space-x-2 text-gray-500 text-sm ">
              <span>{`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(
                currentPage * rowsPerPage,
                audienceList.length
              )} of ${audienceList.length} row(s) selected`}</span>
            </div>
            <div className="flex items-center space-x-4 font-medium text-sm">
              <span>Rows per page:</span>
              <select
                className="ml-2 border border-gray-300 rounded px-2 py-1"
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setCurrentPage(1); // Reset to first page after changing rows per page
                }}
              >
                {[5, 10, 20].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                «
              </button>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                ‹
              </button>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                ›
              </button>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                »
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-[500px]">
          <h2 className="text-xl font-semibold mb-2">
            Here you will see all your Audiences
          </h2>
        </div>
      )}
    </div>
  );
};

export default Audiences;
