import { configureStore } from "@reduxjs/toolkit";
import AuthenticationReducer from "./slices/AuthenticationSlice";
import AdvertiserAccountSlice from "./slices/AdvertiserAccountSlice";
import whatsappSlice from "./slices/WhatsappSlice";
export const store = configureStore({
    reducer: {
        authentication: AuthenticationReducer,
        advertiserAccount: AdvertiserAccountSlice,
        whatsapp:whatsappSlice,
    }
    
})

export type RootState = ReturnType<typeof store.getState>;
export type  AppDispatch = typeof store.dispatch;



