import React, { ChangeEvent, FC, useState , useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { Input } from '../../Components/ui/input';
import { Button } from "../../Components/ui/button";
import { Typography} from "@mui/material";
import { Card } from "src/Components/ui/card";
import axios from "axios";
//import { toast, ToastContainer } from 'react-toastify';
import { toast, useToast } from "../../Components/ui/use-toast";
import { Toaster } from "../../Components/ui/toaster";
import { Image } from 'lucide-react';
import { RootState } from 'src/State/store';
import { useSelector } from 'react-redux';
import config from  '../../config.json';
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectContent,
  SelectValue,
} from "../../Components/ui/select";
import { setworkspace } from "@/src/State/slices/AuthenticationSlice";

const Workspace_settings: FC = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('Dubai Mall');
    const [Streetname, setStreetName] = useState('');
    const [Streetnumber, setStreetnumber] = useState<number | string>(''); // Can store as number or empty string initially
    const [Code, setCode] = useState<number | string>(''); // Postal Code should be an integer
    const [City, setcity] = useState('');
    const [State, setstate] = useState('');
    const [Country, setcountry] = useState('');
    const [Industry, setIndustry] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [nameError, setnameError] = useState<string | null>(null);
    const [StreetNameError , setStreetNameError] = useState <string | null> (null);
    const [StreetNumberError , setStreetNumberError] = useState <string | null> (null);
    const [PostalCodeError , setPostalCodeError] = useState <string | null> (null);
    const [CityError , setCityError] = useState <string | null> (null);
    const [StateError , setStateError] = useState <string | null> (null);
    const [CountryError , setCountryError] = useState <string | null> (null);
    const [IndustryError , setIndustryError] = useState <string | null> (null);
    const [WarningMessage, setWarningMessage] = useState<string | null>(null);
    const [profilePicture, setProfilePicture] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const id=localStorage.getItem("userid"); 
    const Existing_mailId = useSelector((state:RootState)=>state.authentication.userEmail);
    const toast=useToast();

    const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState("");
    
    
    useEffect(() => {
      const fetchConfig = async () => {
        try {
          
          const response = await fetch("/config.json");
          console.log("fetch config::",response);
          const config = await response.json();
        
          console.log("Config loaded:", config); // Debugging log
          setApiUrlAdvAcc(config.ApiUrlAdvAcc); // Set API URL from config
          console.log(apiUrlAdvAcc);
         
        } catch (error) {
          console.error("Error loading config:", error);
        }
      };
  
      fetchConfig();
    }, []);


    useEffect(() => {
      if (apiUrlAdvAcc) {
        const id=localStorage.getItem("userid");
        GetWorkspaceDetailsByEmail();
      }
    }, [apiUrlAdvAcc]); // Runs when apiUrlAdminAcc is updated
  
    
    
    
    
    

    const handleStreetNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      console.log("StreetNumber::" , value);
      // Check if the value contains only numbers
      const regex = /^[0-9]*$/;
  
      // Check if the length is <= 10 (or any other number you prefer)
      if (!regex.test(value)) {
        setStreetNumberError('Street number can only contain numbers.');
      } else if (value.length > 10) {
        setStreetNumberError('Street number cannot be longer than 10 digits.');
      } else {
        setStreetNumberError(''); // Clear the error when the input is valid
        setStreetnumber(value);
      }
    };
  
    const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      console.log("StreetNumber::" , value);
      // Check if the value contains only numbers
      const regex = /^[0-9]*$/;
  
      // Check if the length is <= 10 (or any other number you prefer)
      if (!regex.test(value)) {
        setPostalCodeError('Postal code can only contain numbers.');
      } else if (value.length > 6) {
        setPostalCodeError('Postal number cannot be longer than 6 digits.');
      } else {
        setPostalCodeError(''); // Clear the error when the input is valid
        setCode(value);
      }
    };

    const handleStreetNameChange = (e: ChangeEvent<HTMLInputElement>) => {

    const value = e.target.value;
  
    const nameRegex = /^[a-zA-Z].*$/;

    if (!value) {
      setStreetNameError('Please fill the field.'); 
    } else if (!nameRegex.test(value)) {
      setStreetNameError('Name must start with a letter.'); 
    } else {
      setStreetNameError(''); 
    }

    setStreetName(value); 
    };

     // Handle City input change with validation (only letters)
  const handleCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]*$/; // Allow only letters and spaces

    if (!regex.test(value)) {
      setCityError('City name can only contain letters and spaces.');
    } else {
      setCityError(''); // Clear the error when the input is valid
      setcity(value);
    }
  };

  // Handle State input change with validation (only letters)
  const handleStateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]*$/; // Allow only letters and spaces

    if (!regex.test(value)) {
      setStateError('State name can only contain letters and spaces.');
    } 
    else {
      setStateError(''); 
      setstate(value);
    }
  };

    const handleCompanyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
  
      const nameRegex = /^[a-zA-Z].*$/;
  
      if (!value) {
        setnameError('Please fill the field.'); 
      } else if (!nameRegex.test(value)) {
        setnameError('Name must start with a letter.'); 
      } else {
        setnameError(''); 
      }
  
      setName(value); 
    };
  

    const handleProfileUpdate = async () => {
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        toast.toast({
          title:"Success",
          description: "Profile updated successfully",
        })
       
        setIsLoading(false);
      };
    
      const sample = async () => {
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        toast.toast({
          title: "Success",
          description: "Profile updated successfully",
        })
      
        setIsLoading(false);
      };



      const UpdateWorkspaceAddress = async () => {

        if(StreetNameError || StreetNumberError || PostalCodeError || CityError || StateError){
          return;
        }

        // if (!Streetname){
        //   setStreetNameError('Please select a country before submitting.');
        //   return; 
        // }
       
        
        if (!Streetname || !Streetnumber || !Code|| !City || !State || !Country) {
          setWarningMessage('Please fill in all the fields before submitting.');
          return; // Prevent form submission or API call
        }
        if (!Country) {
          setCountryError('Please select a country before submitting.');
          return; // Prevent form submission or API call
        }
       console.log("value::", State);

        // try{

        // const data={
        // "userEmail": Existing_mailId,
        // "streetName": Streetname || "",
        // "streetNumber": String(Streetnumber) || "",
        // "city": City || "",
        // "postalCode": String(Code) || "",
        // "state": State || "" ,
        // "billingCountry": Country || ""
        // }

        // console.log("data:" + data);
        // console.log('URL:' , config.ApiUrlAdvAcc+"/UpdateWorkspaceAddress/updateWorkSpaceAddress");
       
        // const response = await axios.put( config.ApiUrlAdvAcc+ "/UpdateWorkspaceAddress/updateWorkSpaceAddress", data);


        axios.put(`${apiUrlAdvAcc}/UpdateWorkspaceAddress/updateWorkSpaceAddress`, {
          "userEmail": Existing_mailId,
          "streetName": Streetname || "",
          "streetNumber": String(Streetnumber) || "",
          "city": City || "",
          "postalCode": String(Code) || "",
          "state": State || "" ,
          "billingCountry": Country || ""
      })
      
      .then(response => {
        console.log("response:" , response);
        console.log("res:" , response)
        if (response.data.status === 'Success') {
         
          toast.toast({
            title: "Success",
            description: "Company Address Updated Successfully",
          })

          setTimeout(() => {
          
          }, 3000);
        } else {
          toast.toast({
            title: "Error",
            description: "Company Address Update Failed",
          })
          console.error('Update failed:', response.data.Status_Description);
        }
      })
      .catch(error => {
          toast.toast({
            title: "Error",
            description: "Company Address Update Failed",
          })
        console.error("Error in Workspace Address update", error);
        }) 
      };




    const GetWorkspaceDetailsByEmail = async () => {
      axios
        .post(`${apiUrlAdvAcc}/GetWorkspaceDetailsByEmail`, {
          email: Existing_mailId, // Email ID for the user
        })
        .then((response) => {
          console.log("response:", response);
    
          if (response.data.length > 0 && response.data[0].Status === "Success") {
            const Workspace_Name = response.data[0].Workspace_Name;
            const Billing_Country = response.data[0].Billing_Country;
            const workspace_industry = response.data[0].Workspace_Industry;
            const address = response.data[0].address; // Address JSON string
    
            // Update main details
            setName(Workspace_Name);
            setcountry(Billing_Country);
            setIndustry(workspace_industry);
    
            console.log("Workspace_Name:", Workspace_Name);
            console.log("Billing_Country:", Billing_Country);
            console.log("workspace_industry:", workspace_industry);
    
            // Parse and update address details
            if (address) {
              try {
                const parsedAddress = JSON.parse(address);
                setStreetName(parsedAddress.street_name || "");
                setStreetnumber(parsedAddress.street_number || "");
                setCode(parsedAddress.postal_code || "");
                setcity(parsedAddress.city || "");
                setstate(parsedAddress.state || "");
    
                console.log("Address details:", parsedAddress);
              } catch (error) {
                console.error("Error parsing address:", error);
              }
            }
          } else {
            console.error(
              "Failed to get user details:",
              response.data[0]?.Status_Description
            );
          }
        })
        .catch((error) => {
          console.error("Error in submitting form:", error);
        });
    };
    
    const UpdateWorkSpaceIndustry = async () => {
          
      if (!Industry) {
        setIndustryError('Please select a Industry before submitting.');
        return; // Prevent form submission or API call
      }
      // try{
      //   console.log("workspaceIndustry:" + Industry);
      // const data={
      // "userEmail": Existing_mailId,  
      // "workspaceIndustry" : Industry
      // }
      // console.log("data:" ,data);
     
      // console.log ("API:" , config.ApiUrlAdvAcc+"/UpdateWorkspaceIndustry/updateWorkspaceIndustry", data);
        

      // const response = await axios.put(config.ApiUrlAdvAcc+"/UpdateWorkspaceIndustry/updateWorkspaceIndustry", data);

      axios.put(`${apiUrlAdvAcc}/UpdateWorkspaceIndustry/updateWorkspaceIndustry`, {
          "userEmail": Existing_mailId,  
          "workspaceIndustry" : Industry
    })
    
    .then(response => {
        console.log("res:" , response)
      if (response.data.status === 'Success') {
        toast.toast({
          title: "Success",
          description: "Company Industry Updated successfully",
        })

        setTimeout(() => {
        
        }, 3000);
      } else {
        toast.toast({
          title: "Error",
          description: "Company Industry Update Failed",
        })
        console.error('Update failed:', response.data.Status_Description);
      }
      }) .catch ( error => {
        toast.toast({
          title: "Error",
          description: "Company Industry Update Failed",
        })
      console.error("Error in Company Industry update", error);
      })

        };
                  
            const handleCompanyUpdate = async () => {
            if(nameError){
              return;
            }

          //   try{

          //   const data={
          //   "userEmail":  Existing_mailId,
          //   "newWorkspaceName": name

          //   }
          //  const response = await axios.put(config.ApiUrlAdvAcc+"/UpdateWorkSpace/updateWorkSpaceName", data);

          axios.put(`${apiUrlAdvAcc}/UpdateWorkSpace/updateWorkSpaceName`, {
               "userEmail":  Existing_mailId,
               "newWorkspaceName": name
        })
        
        .then(response => {
              console.log("emailId:" ,Existing_mailId );
              console.log("newWorkspaceName" ,name );
              console.log("res:" , response)
            if (response.data.status === 'Success') {
   
              toast.toast({
                title: "Success",
                description: "Company name Updated Successfully",
              })

              setTimeout(() => {
              
              }, 3000);

            } else {
              toast.toast({
                title: "Error",
                description: "Company name Update Failed",
              })
              console.error('Update failed:', response.data.Status_Description);
            }
            }) 
              .catch (error => {
               toast.toast({
                title: "Error",
                description: "Company name Update Failed",
              })
            console.error("Error in Profile update", error);
            })

            };

            useEffect(() => {
            const id=localStorage.getItem("userid");
            console.log("id :"+ id);
            // handleCompanyUpdate();
            // setName(name);

            }, []); 
    

      const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
          setProfilePicture(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result as string); 
          };
          reader.readAsDataURL(file);
        }
      };

    
      const handleAccountDeletion = () => {
        toast.toast({
          title: "Error",
          description: "Your account has been deleted!",
        })
      
      };

      const countryList = [
        { country_id: "United States", country_name: "United States" },
        { country_id: "Canada", country_name: "Canada" },
        { country_id: "United Kingdom", country_name: "United Kingdom" },
        { country_id: "India", country_name: "India" },
        { country_id: "Australia", country_name: "Australia" },
        { country_id: "United Arab Emirates", country_name: "United Arab Emirates"}
      ];

      const industryList = [
        { industry_id: "IT", industry_name: "IT" },
        { industry_id: "Health", industry_name: "Health" },
        { industry_id: "Finance", industry_name: "Finance" }
      ];

    return (
      <div className="flex h-screen">
      <div className="flex-grow ml-0 p-2 h-screen overflow-y-auto no-scrollbar">
        <Toaster/>
        {/* Comapany logo */}
        <Card className="mb-8 p-6 border border-grey text-left max-w-xl ">
          <Typography
            component="h2"
            className=" mb-4"
            style={{
              fontWeight: 600,
              fontSize: "14px",
              paddingBottom: "6px",
              lineHeight: "24px",
            }}
          >
            <b>Company logo</b>
          </Typography>
          <Typography
            className="mb-4 mt-1"
            style={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#64748B",
              lineHeight: "24px",
            }}
          >
            Update your team's logo to make it easier to identify
          </Typography>
          {/* <input 
            type="file" 
            accept="image/*" 
            onChange={handleImageUpload} 
            className="mb-4"
          />
          {imagePreview && (
            <div className="mb-4 w-32 border border-black overflow-hidden rounded"> 
              <img 
                src={imagePreview} 
                alt="Profile Preview" 
                className="w-full h-auto max-h-32 object-contain" 
              />
            </div>
          )} */}
          <div
            className="flex items-center gap-2 mt-4 cursor-pointer"
            onClick={() => document.getElementById("file-upload")?.click()}
          >
            <div>
              {(!imagePreview && <Image className="h-[24px] w-[24px]" />) ||
                (imagePreview && (
                  <img src={imagePreview} className="h-[24px]" />
                ))}
            </div>
            <div className="flex-col">
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: ' #171717',
                  paddingBottom: '2px'

                }}
              >
                Upload a Profile Picture
              </p>
              <p style={{ fontSize: "10px", fontWeight: 300 , color: '#171717' }}>
                Choose a photo to upload as your profile picture.
              </p>
            </div>
          </div>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
          />
        </Card>

        {/* Profile Information Section */}
        <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
          <Typography
            component="h3"
            className="mb-4"
            style={{ fontWeight: 600, fontSize: "14px", paddingBottom: "6px" }}
          >
            <b>Company Name</b>
          </Typography>
          <Typography
            component="p"
            className="mb-4"
            style={{ fontSize: "14px", fontWeight: 400, color: "#64748B" }}
          >
            Update your team's name
          </Typography>
          <Input
            className="mb-[-2] mt-6"
            value={name}
            // onChange={(e) => setName(e.target.value)}
            onChange={handleCompanyNameChange}
            placeholder="Your name"
            aria-label="Your name"
          />
           {nameError && (
                <p className="text-red-500 text-xs mt-1 mb-2">{nameError}</p>
              )}
          <Button
            onClick={handleCompanyUpdate}
            disabled={isLoading}
            className="py-1 px-3 text-sm w-[82px] mt-4" style={{fontWeight: 500, fontSize: '14px'}}
          >
            {isLoading ? "Updating..." : "Update"}
          </Button>
        </Card>

        {/* Update Email Section */}
        <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
          <Typography
            component="h3"
            className="mb-4"
            style={{ fontWeight: 600, fontSize: "14px", paddingBottom: "6px" }}
          >
            <b>Company Address</b>
          </Typography>
          <Typography
            component="p"
            className="mb-4"
            style={{ fontSize: "14px", fontWeight: 400, color: "#64748B" }}
          >
            Update your company address
          </Typography>
          {WarningMessage && (
           <p className="text-red-500 text-xs mt-1 mb-2">{WarningMessage}</p>
          )}
          <Input
            className="mb-4 mt-4"
            value={Streetname}
            // onChange={(e) => setStreetName(e.target.value)}
            onChange={handleStreetNameChange}
            placeholder="Street name"
            aria-label="Street Name" />
            {StreetNameError && (
                <p className="text-red-500 text-xs mt-1 mb-2">{StreetNameError}</p>
              )}
          
          <Input
            className="mb-4 mt-4"
            value={Streetnumber}
            // onChange={(e) => setStreetnumber(e.target.value)}
            onChange={handleStreetNumberChange}
            placeholder="Street number"
            aria-label="Street number"
          />
           {StreetNumberError && (
                <p className="text-red-500 text-xs mt-1 mb-2">{StreetNumberError}</p>
              )}
          {/* <Input className="mb-4 mt-4" value={Streetnumber} onChange={handleStreetNumberChange} placeholder="Street number" aria-label="Street Number" /> */}
          <Input
            className="mb-4 mt-4"
            value={City}
            // onChange={(e) => setcity(e.target.value)}
            onChange={handleCityChange}
            placeholder="City"
            aria-label="City"
          />
          {CityError && (
                <p className="text-red-500 text-xs mt-1 mb-2">{CityError}</p>
              )}
          <Input
            className="mb-4 mt-4"
            value={Code}
            //  onChange={(e) => setCode(e.target.value)}
             onChange={handlePostalCodeChange}
            placeholder="Postal Code"
            aria-label="Postal Code"
          />
          {PostalCodeError && (
                <p className="text-red-500 text-xs mt-1 mb-2">{PostalCodeError}</p>
              )}

          {/* <Input className="mb-4 mt-4" value={Code} onChange={handlePostalCodeChange} placeholder="Postal Code" aria-label="Postal Code" /> */}
          <Input
            className="mb-4 mt-4"
            value={State}
            required
            // onChange={(e) => setstate(e.target.value)}
            onChange={handleStateChange}
            placeholder="State"
            aria-label="State"
          />
          {StateError && (
                <p className="text-red-500 text-xs mt-1 mb-2">{StateError}</p>
              )}
          <Select
            value={Country}
            onValueChange={(value) => {
              console.log("Selected Country ID:", value);
              setcountry(value);
            }}
          >
            <SelectTrigger className="text-gray-500 mt-4 text-left w-full p-2 border border-gray-300 rounded">
              <SelectValue
                className="text-gray-500 "
                placeholder="Select Country"
              />
            </SelectTrigger>
            <SelectContent>
              {countryList.map((country) => (
                <SelectItem
                  className="text-gray-500"
                  key={country.country_id}
                  value={country.country_id}
                >
                  {country.country_name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {CountryError && (
            <Typography className="text-red-500 mb-4">{CountryError}</Typography>
          )}
          <Button
            onClick={UpdateWorkspaceAddress}
            disabled={isLoading}
            className="py-1 px-3 text-sm w-[204px] mt-4"
          >
            {isLoading ? "Updating..." : "Update Company address"}
          </Button>
        </Card>

        <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
          <Typography
            component="h3"
            className="mb-4"
            style={{ fontWeight: 600, fontSize: "14px", paddingBottom: "6px" }}
          >
            <b>Company Industry</b>
          </Typography>
          <Typography
            component="p"
            className="mb-4"
            style={{ fontSize: "14px", fontWeight: 400, color: "#64748B" }}
          >
            We collect this information for analytics and to provide more
            accurate guidance for your account.
          </Typography>

          <Select
            value={Industry}
            onValueChange={(value) => {
              console.log("Selected Industry:", value); // Log the selected value
              setIndustry(value); // Update state with the selected value
            }}
          >
            <SelectTrigger className="text-gray-500 mt-4 text-left w-full p-2 border border-gray-300 rounded">
              <SelectValue
                className="text-gray-500"
                placeholder="Select Industry"
              />
            </SelectTrigger>
            <SelectContent>
              {industryList.map((industry) => (
                <SelectItem
                  className="text-gray-500"
                  key={industry.industry_id}
                  value={industry.industry_id}
                >
                  {industry.industry_name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
 {IndustryError && (
            <Typography className="text-red-500 mb-4">{IndustryError}</Typography>
          )}
          <Button
            onClick={UpdateWorkSpaceIndustry}
            disabled={isLoading}
            className="py-1 px-3 text-sm w-[140px]"
          >
            {isLoading ? "Updating..." : "Update Industry"}
          </Button>
          
        </Card>

        {/* Delete Account Section */}
        <Card className="mb-8 p-6 border border-grey text-left max-w-xl border-2 border-red-600">
          <Typography
            component="h3"
            className="mb-4"
            style={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "24px",
              color: "#020617",
              letterSpacing: "-1.5%",
              paddingBottom: "6px",
            }}
          >
            {" "}
            Danger zone
          </Typography>
          <Typography
            component="p"
            className="mb-4"
            style={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#64748B",
            }}
          >
            Some actions cannot be undo. Please be careful.
          </Typography>
          <Typography
            component="h3"
            className="mb-4"
            style={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "24px",
              color: "#020617",
              letterSpacing: "-1.5%",
              paddingBottom: "6px",
            }}
          >
            Delete team
          </Typography>
          <Typography
            component="p"
            className="mb-4"
            style={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#64748B",
            }}
          >
            This action cannot be undone. All data associated with this team
            will be deleted.
          </Typography>
          <Button
            onClick={handleAccountDeletion}
            className="bg-red-600 text-white py-2 px-4 text-sm hover:bg-red-700 w-[167px]"
          >
            Delete your account
          </Button>
        </Card>
        <div className="mb-20" />
      </div>
    </div>
  );
};

export default Workspace_settings;
